import React, { ChangeEvent, FocusEvent } from "react";
import { Controller } from "react-hook-form";
import type { Control, FieldValues, Path, ValidateResult } from "react-hook-form";

interface Rule {
    validate?: {
        [key: string]: (value: string) => ValidateResult;
    };
}

export interface ControlledFormFieldProps<T extends FieldValues> {
    className?: string;
    control: Control<T>;
    extraAriaDescribedBy?: string;
    id: string;
    label: string;
    maxLength?: number;
    name: Path<T>;
    onBlur?: (e: FocusEvent<HTMLInputElement, Element>) => void;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement, Element>) => void;
    rules?: Rule;
    type?: string;
}

const ControlledFormField = <T extends FieldValues>({
    className = "",
    control,
    id,
    label,
    name,
    maxLength,
    onBlur,
    onChange,
    onFocus,
    rules,
    type = "text",
    extraAriaDescribedBy
}: ControlledFormFieldProps<T>) => {
    // Determine if a field is required from custom rules
    const isRequired = !!(
        rules?.validate && Object.prototype.hasOwnProperty.call(rules.validate, "isRequired")
    );
    const showDOBSubFields = name === "dateOfBirth";
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, fieldState: { error } }) => {
                const errorState = error?.types;
                const errors = errorState && Object.entries(errorState);
                // Build an array of IDs for aria-describedby
                const describedByIds: string[] = [];
                if (errors && errors.length > 0) {
                    describedByIds.push(`${id}-error`);
                }
                if (isRequired) {
                    if (extraAriaDescribedBy != null) {
                        describedByIds.push(extraAriaDescribedBy);
                    }
                }
                return (
                    <div
                        className={`form-group ${errors && errors.length > 0 ? "has-error" : ""}`}
                        data-testid={`controlled-form-field-${name}`}
                    >
                        <label className="control-label" htmlFor={id}>
                            {label}
                            {showDOBSubFields && (
                                <span className="dob-sub-text">&nbsp;&nbsp;MM/DD/YYYY</span>
                            )}
                        </label>
                        <input
                            className={`form-control ${className}`}
                            data-testid={`controlled-form-field-input-${name}`}
                            {...field}
                            id={id}
                            maxLength={maxLength}
                            onBlur={(event) => {
                                if (onBlur) {
                                    onBlur(event);
                                }
                                field.onBlur();
                            }}
                            onChange={(event) => {
                                field.onChange(onChange ? onChange(event) : event);
                            }}
                            onFocus={onFocus}
                            type={type}
                            required={isRequired}
                            aria-describedby={
                                errors && errors.length > 0 ? describedByIds.join(" ") : undefined
                            }
                            aria-invalid={!!error}
                        />
                        {errors &&
                            errors.map(([key, message]) => (
                                <div
                                    key={key}
                                    id={`${id}-error-${key}`}
                                    aria-live="polite"
                                    data-testid={`error-${key}`}
                                    className="error-block"
                                    aria-describedby={
                                        errors && errors.length > 0
                                            ? describedByIds.join(" ")
                                            : undefined
                                    }
                                >
                                    {message}
                                </div>
                            ))}
                    </div>
                );
            }}
        />
    );
};

export default ControlledFormField;
