import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslations } from "../../queries";
import { NoContactInfoModalProps, LinkablePlansTranslations } from "./types";

const NoContactInfoModal = ({
    plansFromCurrentContext,
    setShowModal,
    showContactModal
}: NoContactInfoModalProps) => {
    const cancelModal = () => {
        setShowModal(false);
    };

    const { noContactInfoModal } = useTranslations<LinkablePlansTranslations>();
    let planContactPhoneNumber = "";

    if (plansFromCurrentContext && plansFromCurrentContext.length === 1) {
        planContactPhoneNumber = plansFromCurrentContext[0].planContactPhoneNumber || "";
    }

    const renderPlanName = (plansFromCurrentContext) => {
        return plansFromCurrentContext.map((planValue) => {
            if (planValue.planContactPhoneNumber) {
                planContactPhoneNumber = planValue.planContactPhoneNumber;
            }
            return (
                <div key={planValue.planName} className="block-title-linking">
                    <b>{planValue.planName}</b>
                </div>
            );
        });
    };

    return (
        <Modal dialogClassName={"modal-wrapper"} show={showContactModal}>
            <div
                data-testid="no-contact-info-modal"
                className="modal-content"
                role="dialog"
                aria-hidden="false"
            >
                <Modal.Header>{renderPlanName(plansFromCurrentContext)}</Modal.Header>
                <Modal.Body>
                    <div className="modal-well with-background">
                        {plansFromCurrentContext.length === 1 && (
                            <p>
                                {noContactInfoModal.intro.replace(
                                    "{{phoneNum}}",
                                    planContactPhoneNumber
                                )}
                            </p>
                        )}
                        {plansFromCurrentContext.length > 1 && (
                            <p>
                                {noContactInfoModal.introWithMorePlans.replace(
                                    "{{phoneNum}}",
                                    planContactPhoneNumber
                                )}
                            </p>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-primary margin-top-default oneid-button-large"
                        onClick={cancelModal}
                        aria-label={noContactInfoModal.ok}
                    >
                        {noContactInfoModal.ok}
                    </button>
                </Modal.Footer>
            </div>
        </Modal>
    );
};

export default NoContactInfoModal;
