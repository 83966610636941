import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import AllFlowsContactInfoUpdate from "./AllFlowsContactInfoUpdate";
import RegisterCredentials from "./RegisterCredentials";
import { useForm } from "react-hook-form";
import { useTranslations } from "../../queries";
import SubmitButton from "./SubmitButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getUpdateContactInfoJson } from "./helpers";
import { registerUser, updateContact } from "../../services/accountSetup/accountSetupService";
import type {
    SetupTranslations,
    SaveErrorData,
    SaveErrorState,
    RegisterFields,
    Translations
} from "./types";
import useRedirect from "../../hooks/useRedirect";
import { addNPDIppt, retrieveDropdownValuesOnNpdiPageLoad } from "../../services/npdi/npdiService";
import type { AxiosResponse } from "axios";

interface RegisterParams {
    contactInfo?: object;
    flowName: string;
    isLawOfficer?: boolean;
    password: string;
    username: string;
}

const AccountSetup = () => {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
        trigger,
        control
    } = useForm<RegisterFields>({
        criteriaMode: "all",
        mode: "all"
    });
    const location = useLocation();
    // temp disable react-hooks/exhaustive-deps until can figure out multiple re-renders where flowName is null
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const flowName = useMemo(() => location.state?.flowName || "registrationFlow", []);
    const isNpdiFlow = flowName === "npdiAccountSetupFlow";
    const { data: npdiData } = useQuery({
        queryKey: ["retrieve-dropdown"],
        queryFn: () => retrieveDropdownValuesOnNpdiPageLoad(),
        enabled: isNpdiFlow
    });

    const translations: Translations = useTranslations();
    const { accountSetup }: { accountSetup: SetupTranslations } = translations;
    const [saveError, setSaveError] = useState<SaveErrorState | null>(null);
    const [errMsg, setErrMsg] = useState("");
    const updateContactMutation = useMutation({
        mutationFn: updateContact,
        onError: (err: SaveErrorData) => {
            const errData = err.data.error;
            setSaveError(errData);
        }
    });
    const registrationMutation = useMutation({
        mutationFn: registerUser
    });
    const addNpdiPptMutation = useMutation({
        mutationFn: addNPDIppt
    });
    const redirect = useRedirect();

    const setupAccount = async (data) => {
        const contactParams = getUpdateContactInfoJson(data, flowName);
        const registerParams: RegisterParams = {
            username: data.username,
            password: data.password,
            flowName
        };
        let resErrMsg: string = "";
        let response = {
            accessPortalGroups: [],
            destinationUrl: "",
            state: ""
        };

        if (errMsg) {
            setErrMsg("");
        }

        try {
            if (isNpdiFlow) {
                registerParams.contactInfo = contactParams;

                if (registerParams.isLawOfficer) {
                    registerParams.isLawOfficer = npdiData.lawEnforcementOfficer;
                }

                response = await addNpdiPptMutation.mutateAsync(registerParams);
            } else {
                await updateContactMutation.mutateAsync(contactParams);
                response = await registrationMutation.mutateAsync(registerParams);
            }
        } catch (err) {
            if (typeof err === "object") {
                const errRes = err as AxiosResponse;
                const code = errRes.data.error.code;

                resErrMsg = translations[code] || "";

                setErrMsg(resErrMsg);
            }
        } finally {
            if (!resErrMsg) {
                redirect(response);
            }
        }
    };

    const showSpinner =
        updateContactMutation.status === "pending" || registrationMutation.status === "pending";

    return (
        <div className="container row registration-container">
            <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
                <header className="contained-form-header">
                    <h1>{accountSetup.setUpAccountTitle}</h1>
                    <p>{accountSetup.completeRegistrationInfo}</p>
                </header>

                <div className="inner-container with-padding with-shadow">
                    {errMsg && (
                        <div className="alert alert-warning">
                            <p>{errMsg}</p>
                        </div>
                    )}
                    <form
                        id="registration-form"
                        name="accountSetupForm"
                        className="clearfix"
                        onSubmit={handleSubmit(setupAccount)}
                        noValidate
                        autoComplete="off"
                    >
                        <h2>{accountSetup.contactInformationTitle}</h2>

                        <AllFlowsContactInfoUpdate
                            register={register}
                            trigger={trigger}
                            errors={errors}
                            saveError={saveError}
                            control={control}
                            isNpdiFlow={isNpdiFlow}
                        />

                        <hr />
                        <h2> {accountSetup.usernamePasswordTitle}</h2>

                        <RegisterCredentials
                            register={register}
                            getValues={getValues}
                            trigger={trigger}
                            errors={errors}
                        />

                        <div className="form-group clearfix">
                            <SubmitButton showSpinner={showSpinner} isRegistrationFlow={true} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AccountSetup;
