import { REDWOOD_MFA } from "../../../routes";

type AllCredential = {
    name: string;
    status: string;
};

type SPData = {
    allCredentials: AllCredential[];
    credentials: string[];
    verificationOptions: string[];
};

type SPHeader = {
    SP_HEADER_VERSION: number;
    accountsMetaData: string[];
    authLevel: string;
    betaTester: boolean;
    csrf: string;
    developer: boolean;
    deviceName: string;
    isDelegate: boolean;
    personId: number;
    qualifiedLead: boolean;
    status: string;
    success: boolean;
    userGuid: string;
    userStage: string;
    username: string;
};

type RedwoodAuthenticateTokenResponseData = {
    data: {
        spData: SPData;
        spHeader: SPHeader;
    };
};

type DeepLink = {
    featureName: string;
    gaId?: string;
    params: string;
};

type DeepLinkCust = {
    freemiumOverride: boolean;
    menuAccessCode: string;
    url: string;
};

const loadFTD = (
    response: RedwoodAuthenticateTokenResponseData,
    loginDomain: string,
    state: any,
    deeplink?: DeepLink,
    deeplinkCust?: DeepLinkCust
) => {
    //Land on freemium dashboard page
    const spHeader = response.data.spHeader;
    const override = globalThis.deepLinkCust?.freemiumOverride || deeplinkCust?.freemiumOverride;
    if (spHeader && spHeader.authLevel == "SESSION_AUTHENTICATED" && spHeader.status == "ACTIVE") {
        window.csrf = spHeader.csrf;
        sessionStorage.setItem("userGuidToken", spHeader.userGuid);
        sessionStorage.setItem("authenticated", spHeader.username);
        if ((deeplink || deeplinkCust) && override) {
            const featureName: any = deeplinkCust
                ? deeplinkCust.menuAccessCode
                : deeplink?.featureName;
            let deepLinkUrl = globalThis.deepLinkCust?.url || deeplinkCust?.url;
            deepLinkUrl = deepLinkUrl.replace(":queryParams", "");
            deepLinkUrl = deepLinkUrl.replace("participant/home", "dashboard");

            sessionStorage.setItem("deeplinkFeature", featureName);

            (window as Window).location = `/${deepLinkUrl}`;
        } else {
            const marketingParamValue = sessionStorage.getItem("marketingParam");
            const marketingParammMapping = globalThis.window.accuCustomization?.marketingParam;
            if (
                marketingParamValue &&
                marketingParammMapping &&
                marketingParammMapping[marketingParamValue]
            ) {
                (window as Window).location = marketingParammMapping[marketingParamValue];
            } else {
                const redirectUrl = "" + "/dashboard/#/home";
                (window as Window).location = redirectUrl;
            }
        }
    } else {
        window.csrf = spHeader.csrf;
        if (state) {
            state.go("redwoodMfa");
        } else {
            window.location.href = `${loginDomain}/participant/#` + REDWOOD_MFA;
        }
    }
};

export default loadFTD;
