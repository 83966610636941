import axios, { all, spread } from "axios";
import { get as _get, forEach as _forEach } from "lodash";

import { handleFault, FAIL } from "./errorUtils";

export const executeRequest = async (request, faultLabel) => {
    try {
        const response = await axios(request);
        checkResponseForError(response);
        return response;
    } catch (err) {
        throw handleFault(err.response, faultLabel);
    }
};
const executeGetCache = {};
export const executeGet = async (url, faultLabel, params, ignoreCache = false) => {
    if (executeGetCache[url] && !ignoreCache) {
        return executeGetCache[url];
    }
    try {
        const response = await axios.get(url, { ...params, withCredentials: true });
        checkResponseForError(response);
        executeGetCache[url] = response;
        return response;
    } catch (err) {
        throw handleFault(err.response, faultLabel);
    }
};

export const executeGetWithAuth = async (url, faultLabel, params) => {
    if (executeGetCache[url]) {
        return executeGetCache[url];
    }
    try {
        const response = await axios.get(url, {
            ...params,
            withCredentials: true,
            headers: {
                Authorization: undefined
            }
        });
        checkResponseForError(response);
        executeGetCache[url] = response;
        return response;
    } catch (err) {
        throw handleFault(err.response, faultLabel);
    }
};

export const executeGetAll = (urls, faultLabel) => {
    return all(urls)
        .then(
            spread((...responses) => {
                _forEach(responses, (response) => {
                    checkResponseForError(response);
                });
                return responses;
            })
        )
        .catch((err) => {
            throw handleFault(err.response, faultLabel);
        });
};

export const executePost = async (url, faultLabel, params, options) => {
    try {
        const response = await axios.post(url, params, options);
        checkResponseForError(response);
        return response;
    } catch (err) {
        throw handleFault(err.response, faultLabel);
    }
};

export const executePostWithIdProof = async (url, faultLabel, params) => {
    axios.defaults.headers.common["Authorization"] = "";
    axios.defaults.headers.common["Idproofenabled"] = true;

    const headers = {
        "Content-Type": "application/json"
    };
    try {
        const response = await axios.post(url, params, { withCredentials: true, headers: headers });
        checkResponseForError(response);
        return response;
    } catch (err) {
        throw handleFault(err.response, faultLabel);
    }
};

export const executePostWithAuth = async (url, faultLabel, params) => {
    if (params.accu !== "Empower" && params.accu !== "FREE" && params.accu !== "MYERIRA") {
        axios.defaults.headers.common["Authorization"] = "";
        axios.defaults.headers.common["Idproofenabled"] = true;
    }
    const headers = {
        "Content-Type": "application/json"
    };
    try {
        const response = await axios.post(url, params, { withCredentials: true, headers: headers });
        checkResponseForError(response);
        return response;
    } catch (err) {
        throw handleFault(err.response, faultLabel);
    }
};
export const executePostWithPcapAuth = async (url, faultLabel, params) => {
    const headers = {
        "Content-Type": "multipart/form-data"
    };
    try {
        const response = await axios.post(url, params, { withCredentials: true, headers: headers });
        checkResponseForError(response);
        return response;
    } catch (err) {
        throw handleFault(err.response, faultLabel);
    }
};
export const executeGetWithPcapAuth = async (url, faultLabel, params) => {
    const headers = {
        "Content-Type": "multipart/form-data"
    };
    try {
        const response = await axios.get(url, params, { withCredentials: true, headers: headers });
        checkResponseForError(response);
        return response;
    } catch (err) {
        throw handleFault(err.response, faultLabel);
    }
};

const checkResponseForError = (response) => {
    if (!_get(response, "data") || _get(response, "data.status") === FAIL) {
        throw response;
    }
};
