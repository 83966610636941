import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../queries/constants";
import { retrievePlansAvailableForLinking } from "../../services/oneId/linkablePlansService";

const useLinkablePlans = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_LINKABLE_PLANS],
        queryFn: async () => {
            const data = await retrievePlansAvailableForLinking();
            return data;
        }
    });
};

export default useLinkablePlans;
