import React, { useState, useReducer, useCallback } from "react";
import {
    authenticateTokenPcap,
    authenticateLite,
    authenticatePart,
    onAuthenticateSuccess,
    onAuthenticateFailure
} from "../helpers/authenticate";
import LoginForm from "../LoginForm";
import useAuthenticateReducer, { initialState } from "../useAuthenticateReducer";
import redwoodAuthenticateToken from "../../../services/redwoodAuthenticateToken";
import loadFtd from "../helpers/loadFtd";
import localBuildConfig from "../../../../../../../build/buildConfig.json";
import {
    getParticipantDataAsync,
    getlogBrowserInfo,
    getRouteDetermination
} from "../../../services/stateDestinationService"; // Ensure correct import

// We should be able to use the iframes window context to determine the login domain: ex: "proj10.retirementpartner.com"
const LOGIN_DOMAIN = window.location.origin || "https://participant.empower-retirement.com";

export interface LoginFormContainerNativeProps {
    accuCodeIn: string | null;
    fromIn: string;
    isLoginPartIn: boolean | null | undefined;
}

/**
 * Login Container for react only platform
 *    Also used by drupal iframe login page
 * @param param0
 * @returns
 */
export const multiAuth = localBuildConfig.multiAuth;
export const currentPcBaseApiUrl = multiAuth[window.subdomain] ?? multiAuth["Default"];
const LoginFormContainerNative = ({
    accuCodeIn,
    fromIn,
    isLoginPartIn
}: LoginFormContainerNativeProps) => {
    const [accuCode, setAccuCode] = useState(accuCodeIn || "");
    const [loggingInState, setLoggingInState] = useState(false);
    const [showLogin, setShowLogin] = useState(true);
    const [authentication, dispatch] = useReducer(useAuthenticateReducer, initialState);

    const registerButtonClick = () => {
        setShowLogin(false);
    };

    const openAccountClick = () => {
        setShowLogin(false);
        const href = "/#/articles/MYERIRA/openAnAccount";
        window.location.assign(href);
    };

    const getFeatureValue = () => {
        const paramName = "deeplink";
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const deeplinkParam = urlParams.get(paramName);
        return deeplinkParam || "";
    };

    /**
     * on click submit
     */
    const handleAuthenticate = useCallback(
        async ({ username, password }) => {
            try {
                const isIframe = fromIn === "iframe";
                if (isIframe) {
                    const feature = getFeatureValue();
                    if (feature && feature !== "") {
                        globalThis.deepLinkParams = {
                            featureName: feature,
                            params: ""
                        };
                    }
                }
                const deeplink = globalThis.deepLinkParams;
                const deeplinkParams = deeplink?.params;
                const deeplinkFeature = deeplink?.featureName;
                const partAuthData =
                    !isIframe &&
                    (isLoginPartIn ||
                        accuCode === "Empower" ||
                        accuCode === "FREE" ||
                        accuCode === "MYERIRA")
                        ? await authenticatePart({
                              accuCode,
                              deeplink,
                              username,
                              password,
                              setLoggingInState,
                              dispatch
                          })
                        : null;
                let authData;
                let isFreemium = false;
                const idToken = partAuthData?.idToken;

                if (partAuthData?.authProvider === "EMPOWER") {
                    authData = await authenticateTokenPcap({
                        accuCode,
                        deeplink,
                        idToken,
                        setLoggingInState,
                        dispatch
                    });
                } else if (partAuthData?.authProvider === "REDWOOD") {
                    if (idToken) {
                        const redwoodResponse = await redwoodAuthenticateToken(
                            currentPcBaseApiUrl,
                            idToken
                        );
                        loadFtd(redwoodResponse, LOGIN_DOMAIN, null, deeplink);
                    }
                    isFreemium = true;
                } else {
                    authData = await authenticateLite({
                        accuCode,
                        deeplink,
                        username,
                        password,
                        setLoggingInState,
                        dispatch
                    });
                }
                if (authData.state === null || authData.state === "") {
                    getParticipantDataAsync();
                    getlogBrowserInfo();
                    try {
                        authData = await getRouteDetermination();
                    } catch (error) {
                        console.error("ERROR - routeDetermination api: ", error);
                    }
                } else if (authData.state === "ACTIVATION_CODE_INFO") {
                    getParticipantDataAsync();
                    getlogBrowserInfo();
                    getRouteDetermination();
                }
                const href = await onAuthenticateSuccess({
                    accuCode,
                    authData,
                    dispatch,
                    setLoggingInState,
                    setAccuCode
                });

                let appendIframe = "";
                //Redirect to where it needs to go
                if (fromIn === "iframe") {
                    appendIframe = "iframe=true";
                }

                let redirectUrl = href;

                if (isIframe && deeplinkFeature && deeplinkFeature !== "") {
                    redirectUrl = href + "?noScope=true&deepLinkParam=" + deeplinkFeature;
                } else if (deeplinkParams && deeplinkParams !== "") {
                    redirectUrl = href + "?noScope=true&deepLinkParam=" + deeplinkParams;
                }

                if (fromIn === "iframe") {
                    if (deeplinkFeature && deeplinkFeature !== "") {
                        redirectUrl = redirectUrl + "&" + appendIframe;
                    } else {
                        redirectUrl = redirectUrl + "?" + appendIframe;
                    }
                }

                //  Next line used for testing locally
                //  redirectUrl = "http://localhost:3005" + redirectUrl;
                window.sessionStorage.setItem("isFreemium", String(isFreemium));
                window.location.assign(redirectUrl);
            } catch (error) {
                const href = onAuthenticateFailure({
                    error: error,
                    dispatch,
                    setLoggingInState,
                    setShowLogin,
                    loginDomain: LOGIN_DOMAIN,
                    accuCode
                });

                if (href) {
                    //Redirect to where it needs to go
                    window.location.assign(href);
                }
            }
        },
        [accuCode, fromIn, isLoginPartIn]
    );

    const isReactUrl = String(window.location.href).indexOf("isReact=true") > -1;
    const updateStyleClass = {
        color: "white"
    };

    return (
        <div data-testid="login-container">
            {isReactUrl && <h1 style={updateStyleClass}>Test Landing Login page</h1>}
            <LoginForm
                accu={accuCode}
                authentication={authentication}
                authenticateInput={handleAuthenticate}
                loggingIn={loggingInState}
                showLoginUpd={showLogin}
                errorCode={authentication.errorCode}
                errorMessageParams={authentication.errorMessageParams}
                registerButtonClickInput={registerButtonClick}
                openAccountClickInput={openAccountClick}
                loginDomain={LOGIN_DOMAIN}
                from={fromIn}
            />
        </div>
    );
};

export default LoginFormContainerNative;
