/* eslint-disable react-hooks-extra/no-direct-set-state-in-use-effect */
import React, { useState, useCallback, useEffect, useRef } from "react";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslations } from "../../queries";
import {
    type LinkablePlansTranslations,
    type PlanValues,
    type IPlansMapWithIndIdDbName
} from "./types";
import { forEach as _forEach, find as _find, isEmpty as _isEmpty } from "lodash";

import useLinkablePlans from "../../queries/linkablePlans/useLinkablePlans";
import useLinkablePlansProfile from "../../queries/linkablePlans/useLinkablePlansProfile";
import {
    getContextEligibilityForHiding,
    linkAccounts,
    updatePinAuthCode
} from "../../services/oneId/linkablePlansService";
import eventBus from "../../../utils/setEventBus";
import oneIdEvents from "../../../oneid/events/OneIdEvents";
import NoContactInfoModal from "./NoContactInfoModal";
import useRedirect from "../../hooks/useRedirect";
import { ACTIVATION_CODE_DELIVERY_OPTIONS, LINKABLE_PLANS } from "../../routes";

interface LinkablePlansProps {
    location?: { path: (path: string) => void };
    redirectService?: {
        redirect: (data: { destinationUrl?: string; state: string }, key: string) => void;
    };
    scope?: { $apply: () => void; $root: { featureName: string } };
}

interface ContextEligibilityParams {
    accuCode: string;
    dbName: string;
    indId: string;
}

export type OneIdEventKey = string | string[] | null | undefined;

export interface OneIdOptionsFields {
    selectedOption: OneIdEventKey;
    selectedValue: string;
}

const LinkablePlansComponent = ({ location, scope }: LinkablePlansProps) => {
    const { linkablePlans } = useTranslations<LinkablePlansTranslations>();

    const [loading, setLoading] = useState(false);
    const [linkedAccountsDone, setLinkAccountsDone] = useState(false);
    // eslint-disable-next-line
    const [queryKey, setQueryKey] = useState("");
    const [showError, setShowError] = useState(false);

    const {
        data: linkablePlansData,
        error: linkablePlansError,
        isSuccess: linkPlansSuccess,
        isLoading: linkPlansLoading,
        refetch: refetchLinkablePlans
    } = useLinkablePlans();

    const {
        data: linkablePlansProfileData,
        error: linkablePlansProfileError,
        isSuccess: profileSuccess,
        isLoading: profileLoading,
        refetch: refetchLinkablePlansProfile
    } = useLinkablePlansProfile();

    const [selectedIndidsForLinking, setSelectedIndidsForLinking] = useState<String[]>([]);

    const [plansMapWithIndIdDbName, setPlansMapWithIndIdDbName] = useState({});
    const [currentUserPlans, setCurrentUserPlans] = useState<PlanValues[]>([]);

    const [linkingContext, setLinkingContext] = useState("");
    const [showContactModal, setShowContactModal] = useState(false);

    // @ts-ignore
    const STYLE_ENV = __styleEnv__;
    const redirect = useRedirect();
    const { state } = useLocation();

    const hasPlansMap = Object.keys(plansMapWithIndIdDbName).length > 0;

    const pinAuthCodeMutation = useMutation({
        mutationFn: updatePinAuthCode
    });
    const contextEligibilityHidingMutation = useMutation({
        mutationFn: (params: ContextEligibilityParams) => getContextEligibilityForHiding(params),
        onSuccess: (data) => {
            return data;
        }
    });

    const { mutateAsync: linkAccountsMutation } = useMutation({
        mutationFn: linkAccounts,
        onSuccess: (data) => {
            return data;
        }
    });

    let currentAccountEligibleForHiding;
    const isLinkablePlansProfile = window.location.href.indexOf("linkable-plans-profile") > -1;
    const navigate = useNavigate();

    useEffect(() => {
        if (state?.FLOW_NAME === "mfa_oneid") {
            setQueryKey(String(Date.now()));
            if (isLinkablePlansProfile) {
                refetchLinkablePlansProfile();
            } else {
                refetchLinkablePlans();
            }
        }
    }, [
        location,
        isLinkablePlansProfile,
        refetchLinkablePlans,
        refetchLinkablePlansProfile,
        state?.FLOW_NAME
    ]);

    const continueClick = useCallback(
        async (evt?) => {
            evt?.preventDefault();
            setLoading(true);
            eventBus.dispatch(oneIdEvents.CONTINUE_LINKING, this);
            eventBus.dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_LINK,
                event_properties: {
                    selection: oneIdEvents.CONTINUE_LINKING
                }
            });

            let response = {
                destinationUrl: "",
                state: ""
            };

            _forEach(plansMapWithIndIdDbName, function (value: any, key) {
                _forEach(value, function (planValue: any) {
                    if (planValue?.checked === true) {
                        if (selectedIndidsForLinking.indexOf(String(key)) === -1) {
                            selectedIndidsForLinking.push(String(key));
                        }
                    }
                });
            });

            setSelectedIndidsForLinking(selectedIndidsForLinking);

            if (selectedIndidsForLinking.length > 0 && !linkedAccountsDone) {
                try {
                    response = await linkAccountsMutation(selectedIndidsForLinking);
                } catch (e) {
                    console.error(e);
                } finally {
                    setLinkAccountsDone(true);
                    redirect(response);
                    setLoading(false);
                }
            }
        },
        [
            plansMapWithIndIdDbName,
            redirect,
            selectedIndidsForLinking,
            linkedAccountsDone,
            linkAccountsMutation
        ]
    );

    const setCheckedAttributeBasedOnMFAFinishedAttribute = useCallback(
        (linkablePlansMap) => {
            _forEach(linkablePlansMap, function (values: IPlansMapWithIndIdDbName[]) {
                _forEach(values, function (value) {
                    const item = value as IPlansMapWithIndIdDbName;
                    item.checked = value.mfaFinished;
                });
            });
            setPlansMapWithIndIdDbName(linkablePlansMap);
        },
        [setPlansMapWithIndIdDbName]
    );

    useEffect(() => {
        if (isLinkablePlansProfile) {
            if (linkablePlansProfileData && profileSuccess && !profileLoading) {
                setPlansMapWithIndIdDbName(linkablePlansProfileData.linkablePlansMap);
                setCurrentUserPlans(linkablePlansProfileData.currentUserPlans);
                setCheckedAttributeBasedOnMFAFinishedAttribute(
                    linkablePlansProfileData.linkablePlansMap
                );
            } else if (linkablePlansProfileError) {
                console.log(
                    "retrievePlansAvailableForLinkingForProfile - ERROR: ",
                    linkablePlansProfileError
                );
            }
        } else {
            if (linkablePlansData && linkPlansSuccess && !linkPlansLoading) {
                setPlansMapWithIndIdDbName(linkablePlansData.linkablePlansMap);
                setCurrentUserPlans(linkablePlansData.currentUserPlans);
                setCheckedAttributeBasedOnMFAFinishedAttribute(linkablePlansData.linkablePlansMap);

                if (_isEmpty(linkablePlansData.linkablePlansMap)) {
                    continueClick();
                }
            } else if (linkablePlansError) {
                console.log("retrievePlansAvailableForLinking - ERROR: ", linkablePlansError);
            }
        }
    }, [
        linkablePlansData,
        linkPlansSuccess,
        linkPlansLoading,
        linkablePlansError,
        continueClick,
        isLinkablePlansProfile,
        linkablePlansProfileData,
        linkablePlansProfileError,
        profileSuccess,
        profileLoading,
        setCheckedAttributeBasedOnMFAFinishedAttribute
    ]);

    const checkIfLockNeeded = (keyToCompare) => {
        let lockNeeded = true;
        let keeplooking = true;

        _forEach(plansMapWithIndIdDbName, (values, key) => {
            const itemValue = values as IPlansMapWithIndIdDbName[];
            if (keeplooking && keyToCompare === key) {
                // !false lockNeeded = true  first pass
                // !true  lockNeeded = false  second pass
                lockNeeded = !itemValue[0]["mfaFinished"];
                keeplooking = false;
            }
        });

        return lockNeeded;
    };

    const setCurrentAccountEligibleForHiding = async (key) => {
        setLoading(true);
        setShowError(false);
        const [indId, dbName, accuCode] = key.split(":");

        const params = {
            indId,
            dbName,
            accuCode
        };

        let response = {
            gaIdWithNonZeroBalance: "",
            gaIdWithActiveEmployment: "",
            isContextEligibleForHiding: false,
            errorCode: "",
            errorWarnMessage: ""
        };

        try {
            response = await contextEligibilityHidingMutation.mutateAsync(params);
        } catch (e: any) {
            const isContextEligibleForHiding = response.isContextEligibleForHiding || false;
            const errorCode = e?.data?.errorCode;
            currentAccountEligibleForHiding = isContextEligibleForHiding ? key : null;

            setLoading(false);
            if (errorCode) {
                setShowError(true);
                console.log("getContextEligibilityForHiding - ERROR : ", errorCode);
            }

            return { error: errorCode };
        } finally {
            const isContextEligibleForHiding = response.isContextEligibleForHiding || false;
            const errorCode = response?.errorCode || "";
            currentAccountEligibleForHiding = isContextEligibleForHiding ? key : null;
            setLoading(false);

            if (errorCode) {
                console.log("getContextEligibilityForHiding - ERROR  : ", errorCode);
            }
        }
    };

    const verifyAccountButtonVisibility = (key) => {
        const isDisplayingAccountEligibleForHidingSection = currentAccountEligibleForHiding === key;
        if (isDisplayingAccountEligibleForHidingSection) {
            return false;
        }

        return checkIfLockNeeded(key);
    };

    const isEligibleForHidingSectionVisibility = (key) => {
        return currentAccountEligibleForHiding === key;
    };

    const updateAngularRoutingState = useCallback(
        (urlTo) => {
            /**
             * TODO: Delete this function when Angular is removed. Since we are using the React router
             * we need to update the Angular routing state to match the new React routing state.
             */
            if (location && scope) {
                location.path(urlTo);
                scope.$apply();
            }
        },
        [location, scope]
    );

    const verifyAccount = (evt, key) => {
        evt.stopPropagation();
        setLinkingContext(key);
        let keeplooking = true;
        let shouldShowModal = false;
        let shouldNavigateToDeliveryOptions = false;
        let shouldNavigateToLinkablePlans = false;

        // eslint-disable-next-line no-undef
        _forEach(plansMapWithIndIdDbName, (values, tempKey) => {
            if (key === tempKey && keeplooking) {
                let routeTo = false;
                if (values) {
                    // eslint-disable-next-line no-undef
                    _forEach(values, (value) => {
                        const itemValue = value as IPlansMapWithIndIdDbName;
                        const contactThere = itemValue.contactsThere; // true or false
                        const mfaFinished = itemValue.mfaFinished;

                        if (!routeTo) {
                            if (!contactThere && keeplooking) {
                                shouldShowModal = true;

                                const tempPlansMap = plansMapWithIndIdDbName;

                                _forEach(
                                    tempPlansMap,
                                    function (values: IPlansMapWithIndIdDbName[]) {
                                        _forEach(values, function (value) {
                                            const item = value as IPlansMapWithIndIdDbName;
                                            item.checked = false;
                                        });
                                    }
                                );

                                setPlansMapWithIndIdDbName(tempPlansMap);
                                keeplooking = false;
                            } else if (contactThere && !mfaFinished) {
                                shouldNavigateToDeliveryOptions = true;
                                keeplooking = false;
                                routeTo = true;
                            } else if (contactThere && mfaFinished) {
                                shouldNavigateToLinkablePlans = true;
                                keeplooking = false;
                                routeTo = true;
                            }
                        }
                    });
                }
            }
        });

        if (shouldShowModal) {
            setShowContactModal(true);
        } else if (shouldNavigateToDeliveryOptions) {
            navigate(ACTIVATION_CODE_DELIVERY_OPTIONS, {
                replace: true,
                state: {
                    linkingContextKey: key //indId:dbName:accuCode
                }
            });
            updateAngularRoutingState(ACTIVATION_CODE_DELIVERY_OPTIONS);
        } else if (shouldNavigateToLinkablePlans) {
            const urlToLinkablePlans = LINKABLE_PLANS;
            navigate(urlToLinkablePlans, { replace: true });
            updateAngularRoutingState(LINKABLE_PLANS);
        }
    };

    const onVerifyAccountClick = async (evt, key) => {
        evt.preventDefault();

        try {
            const response = await setCurrentAccountEligibleForHiding(key);

            if (response?.error && response?.error !== "") {
                setShowError(true);
            } else {
                if (key) {
                    verifyAccount(evt, key);
                }
            }
        } catch (error) {
            console.log("setCurrentAccountEligibleForHiding - ERROR: ", error);
        }
    };

    const hideAccount = (evt, key) => {
        evt.stopPropagation();
        setLoading(true);
        const [indId, dbName, accuCode] = key.split(":");
        const params = {
            indId,
            dbName,
            accuCode
        };

        pinAuthCodeMutation.mutate(params);

        setLoading(false);
    };

    /**
     *  mfaFinished is false - first pass
     *  mfaFinished is true - second pass
     * @returns
     */
    const checkForEnabling = () => {
        if (!hasPlansMap) {
            return "is-disabled";
        }

        const checkEnable = _find(plansMapWithIndIdDbName, (account) => {
            const item = account as IPlansMapWithIndIdDbName[];
            return Boolean(item[0].checked) === false;
        });

        return checkEnable === undefined ? "" : "is-disabled";
    };

    const dataForNoContactInfoModal = () => {
        const plans = [] as PlanValues[];
        // eslint-disable-next-line no-undef
        if (hasPlansMap) {
            _forEach(plansMapWithIndIdDbName, function (values, key) {
                const itemValues = values as IPlansMapWithIndIdDbName[];
                if (String(key) === linkingContext) {
                    // eslint-disable-next-line no-undef
                    _forEach(itemValues, function (value: any) {
                        plans.push(value);
                    });
                }
            });
        }
        return plans;
    };

    const plansFromCurrentContext = dataForNoContactInfoModal();
    const checkDisabled = checkForEnabling();
    const isReact = String(window.location.href).indexOf("isReact") > -1;

    const tooltipButtonRef = useRef<HTMLButtonElement>(null);

    /*   useEffect(() => {
        if (tooltipButtonRef.current) {
            tooltipButtonRef.current.focus();
        }
    }, []); */

    return (
        <React.Fragment>
            {showContactModal && (
                <NoContactInfoModal
                    plansFromCurrentContext={plansFromCurrentContext}
                    setShowModal={setShowContactModal}
                    showContactModal={showContactModal}
                />
            )}

            <div className="container row">
                <div
                    className={`col-md-6 ${isReact ? "col-md-offset-7" : "col-md-offset-3"} col-sm-8 col-sm-offset-2 account-selection linkable-plans-template`}
                >
                    <header className="contained-form-header margin-top-default">
                        <h1>{linkablePlans?.header}</h1>
                        <p> {linkablePlans?.intro} </p>
                    </header>
                    {(loading || linkPlansLoading || profileLoading) && (
                        <div id="statementsDocs-spinner" className="loaderBackground just-element">
                            <div className="loader"></div>
                        </div>
                    )}

                    <form
                        className="with-padding with-top-margin margin-bottom-200"
                        name="linkablePlanSelectionForm"
                        data-testid="linkable-plan-selection-form"
                    >
                        <div className="inner-container with-padding with-shadow clearfix">
                            {currentUserPlans && (
                                <div className="current-user-plans">
                                    <div className="flex-heading">
                                        {currentUserPlans.length > 1 && (
                                            <label className="control-label">
                                                {linkablePlans?.thesePlans}
                                            </label>
                                        )}
                                        {currentUserPlans.length == 1 && (
                                            <label className="control-label">
                                                {linkablePlans?.thisPlan}
                                            </label>
                                        )}
                                        <OverlayTrigger
                                            trigger={["click", "focus"]}
                                            placement="right"
                                            rootClose
                                            overlay={
                                                <Popover
                                                    id="linkable-plans-tooltip"
                                                    className="linkable-plans-tooltip"
                                                >
                                                    <ul>
                                                        <li>{linkablePlans?.t1}</li>
                                                        <br />
                                                        <li>{linkablePlans?.t2}</li>
                                                    </ul>
                                                </Popover>
                                            }
                                        >
                                            <button
                                                className="button-as-link"
                                                onClick={(evt) => {
                                                    evt.preventDefault();
                                                }}
                                                ref={tooltipButtonRef}
                                            >
                                                {linkablePlans?.learnMore}
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="well">
                                        <div className="form-group checkbox text-center">
                                            <label>
                                                {currentUserPlans.map((planValue) => {
                                                    return (
                                                        <span
                                                            key={planValue["planName"]}
                                                            style={{
                                                                fontSize: "16px",
                                                                whiteSpace: "normal"
                                                            }}
                                                        >
                                                            {planValue["planName"]}
                                                        </span>
                                                    );
                                                })}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {hasPlansMap && (
                                <div>
                                    <div className="well well--blue">
                                        <span className="em-info-icon"></span>
                                        <span>
                                            {linkablePlans?.lockInfo1}
                                            <img
                                                alt="padlock"
                                                src={
                                                    STYLE_ENV +
                                                    "customizations/Default/images/padlock.svg"
                                                }
                                            />
                                            {linkablePlans?.lockInfo2}
                                        </span>
                                    </div>

                                    {Object.entries(plansMapWithIndIdDbName).map(([key, value]) => {
                                        const itemValue = value as IPlansMapWithIndIdDbName[];
                                        return (
                                            <div key={key} className="well well--outline  tst2 ">
                                                <div>
                                                    {checkIfLockNeeded(key) && (
                                                        <img
                                                            alt="padlock"
                                                            src={
                                                                STYLE_ENV +
                                                                "customizations/Default/images/padlock.svg"
                                                            }
                                                            className="oneid-lock"
                                                        />
                                                    )}
                                                    {!checkIfLockNeeded(key) && (
                                                        <img
                                                            alt="checkmark"
                                                            src={
                                                                STYLE_ENV +
                                                                "customizations/Default/images/login/checkmark.svg"
                                                            }
                                                        />
                                                    )}
                                                    <div className="list2-test">
                                                        {itemValue.map((planValue) => {
                                                            return (
                                                                <div key={key + planValue.planName}>
                                                                    {planValue.planName}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                    {verifyAccountButtonVisibility(key) && (
                                                        <button
                                                            className="btn btn-primary btn-primary--outline"
                                                            onClick={(e) =>
                                                                onVerifyAccountClick(e, key)
                                                            }
                                                        >
                                                            {linkablePlans?.verifyThisAccount}
                                                        </button>
                                                    )}
                                                </div>
                                                {isEligibleForHidingSectionVisibility(key) && (
                                                    <div>
                                                        <p>{linkablePlans?.zeroBalanceAccount}</p>
                                                        <p>
                                                            {
                                                                linkablePlans?.reinitiateZeroBalanceAccount
                                                            }{" "}
                                                        </p>
                                                        <div>
                                                            <button
                                                                className="btn btn-primary btn-primary--outline"
                                                                onClick={(e) =>
                                                                    verifyAccount(e, key)
                                                                }
                                                            >
                                                                {linkablePlans?.keepThisAccount}
                                                            </button>
                                                            <button
                                                                className="btn btn-primary"
                                                                onClick={(e) => hideAccount(e, key)}
                                                            >
                                                                {linkablePlans?.hideThisAccount}
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            {showError && (
                                <div className="error-block" role="alert" aria-live="polite">
                                    {linkablePlans?.somethingWentWrong}
                                </div>
                            )}

                            <div className="row cta investment-button-row">
                                <button
                                    id="submitButton"
                                    type="submit"
                                    aria-disabled={checkDisabled === "is-disabled"}
                                    className={`col-12 btn btn-primary oneid-button-large ${checkDisabled}`}
                                    onClick={(e) => continueClick(e)}
                                >
                                    {linkablePlans?.continue}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
};

export default LinkablePlansComponent;
