import React, { MouseEvent, useCallback, useEffect, useState } from "react";
import { LOGIN_HELP_NO_PASSWORD, LOGIN_HELP_WITH_ACCU, LOGIN_WITH_ACCU } from "../../../routes";
import { getAccuCode } from "../../../services/accuCodeService";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslations } from "../../../queries";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { PerformanceTrackingKeys } from "core-ui/client/react/core/constants/constants";
import { LoginHelpOptionsFields } from "./types";
import eventBus from "../../../../utils/setEventBus";

interface LoginHelpOption {
    id: string;
    subTitle: string;
    title: string;
}

interface LoginHelpOptionsProps {
    accu: string;
    location?: { path: (path: string) => void };
    scope?: { $apply: () => void; $root: { featureName: string } };
}

interface LoginHelpOptionsTranslations {
    "0000": string;
    button: {
        continue: string;
    };
    loginHelpOptions: LoginHelpOption[];
    loginHelpSelectActivationMethodUpdated: string;
    loginHelpUpd: string;
    preference: {
        button: {
            cancel: string;
        };
    };
}

const DEFAULT_VALUES = {
    selectedOption: "",
    selectedValue: ""
};

const trackTimeLoad = function () {
    const signinTime = localStorage.getItem(PerformanceTrackingKeys.PT_SIGNIN_CLICKED);
    const loginHelpLoadedTime = new Date().getTime();
    localStorage.removeItem(PerformanceTrackingKeys.PT_LOGIN_HELP_LOADED);
    localStorage.setItem(PerformanceTrackingKeys.PT_LOGIN_HELP_LOADED, `${loginHelpLoadedTime}`);
    localStorage.setItem(
        PerformanceTrackingKeys.PT_SIGNIN_LOGIN_HELP_DELTA,
        `${loginHelpLoadedTime - parseInt(signinTime ? signinTime : "")}`
    );
};

const LoginHelpMultiOptions = ({ accu, location, scope }: LoginHelpOptionsProps) => {
    const {
        loginHelpUpd,
        loginHelpSelectActivationMethodUpdated,
        loginHelpOptions,
        button,
        preference,
        "0000": errorMessage
    } = useTranslations<LoginHelpOptionsTranslations>();

    const navigate = useNavigate();

    const getAccu = getAccuCode();
    const currentAccu = accu ? accu : getAccu ? getAccu : "Empower";
    const [deepLinkName, setDeepLinkName] = useState("");
    const initialValues = DEFAULT_VALUES;
    const { state } = useLocation();

    const {
        control,
        handleSubmit,
        register,
        setValue,
        watch,
        setFocus,
        formState: { isSubmitting, isValid }
    } = useForm<LoginHelpOptionsFields>({
        values: initialValues
    });

    // eslint-disable-next-line
    const { selectedOption, selectedValue } = watch();

    /**
     * Set up deep link flow in angular code from new login help page
     * @param href
     */
    useEffect(() => {
        trackTimeLoad();

        const extractErrorMessageFromRedirection = (href: string) => {
            if (String(href).includes("noScope")) {
                const start = String(href).indexOf("deepLinkParam") + 14;
                let deepLinkParam = String(href).substring(start);

                if (String(deepLinkParam).indexOf("iframe") !== -1) {
                    const deepLinkName = String(deepLinkParam).split("&");
                    deepLinkParam = deepLinkName[0];
                    // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
                    setDeepLinkName(deepLinkParam);
                }
            }
        };

        extractErrorMessageFromRedirection(window.location.href);

        if (scope && scope.$root && deepLinkName === "") {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setDeepLinkName(scope.$root.featureName);
        }
    }, [deepLinkName, scope]);

    useEffect(() => {
        setFocus("selectedValue");
    }, [setFocus]);

    const updateAngularRoutingState = useCallback(() => {
        /**
         * TODO: Delete this function when Angular is removed. Since we are using the React router
         * we need to update the Angular routing state to match the new React routing state.
         */
        if (location && scope) {
            location.path(LOGIN_HELP_WITH_ACCU + currentAccu);
            scope.$apply();
        }
    }, [location, scope, currentAccu]);

    const handleContinue = useCallback(
        async (formData: LoginHelpOptionsFields) => {
            const { selectedOption, selectedValue } = formData;
            const failedAffiliate = state ? state?.failedAffiliate : "";
            const failedFreemium = state ? state?.failedFreemium : "";

            if (selectedOption === "affiliate" || selectedValue === "affiliate") {
                try {
                    navigate("/" + LOGIN_HELP_WITH_ACCU + currentAccu, {
                        replace: true,
                        state: {
                            type: selectedOption,
                            failedAffiliate: failedAffiliate,
                            failedFreemium: failedFreemium
                        }
                    });
                } catch (error) {
                    console.error(error);
                    control.setError("root", { message: errorMessage });
                }
            } else {
                navigate("/" + LOGIN_HELP_NO_PASSWORD, {
                    replace: true,
                    state: {
                        type: selectedOption,
                        failedAffiliate: failedAffiliate,
                        failedFreemium: failedFreemium
                    }
                });
                updateAngularRoutingState();
            }
        },
        [control, errorMessage, updateAngularRoutingState, navigate, currentAccu, state]
    );

    // eslint-disable-next-line react-hooks-extra/no-unnecessary-use-callback
    const dispatchAmplitudeEvent = useCallback((event: MouseEvent) => {
        //eslint-disable-next-line
        const payload = String(event.target["id"]).toLowerCase();
        eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_BUTTON, event.target, payload);

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: payload,
            payload: {
                payload
            }
        });
    }, []);

    /**
     * Accessibility - Tab in first radio, use onFocus to capture event
     * @param e
     */
    const handleRadioFocus = (e: React.FocusEvent) => {
        if (e.target && e.target["id"]) {
            handleRadioToggle(e.target["id"]);
            e.stopPropagation();
        }
    };

    const handleRadioToggle = (id) => {
        const payload = String(id).toLowerCase();
        if (selectedValue !== id) {
            eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_BUTTON, id, payload);

            dispatchAmplitude({
                eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
                selection: payload,
                payload: {
                    payload
                }
            });

            setValue("selectedOption", id);
            setValue("selectedValue", id, { shouldValidate: true });
        }
    };

    /**
     * Move handle radio toggle to allow user to click on any where in the div panel which include the radio button and text
     * as well as empty areas within the div
     * @param id
     */
    const handleDivChange = (id: string) => {
        handleRadioToggle(id);
    };

    const handleCancel = (e: MouseEvent) => {
        //eslint-disable-next-line
        if (String(e.target["id"]).toLowerCase() !== "cancel") {
            return;
        }
        e.stopPropagation();
        dispatchAmplitudeEvent(e);
        navigate("/" + LOGIN_WITH_ACCU + currentAccu, { replace: true });
        window.location.reload();
    };

    return (
        <div className="loginhelp-container">
            <form data-testid="loginhelp-options" onSubmit={handleSubmit(handleContinue)}>
                {isSubmitting && <div className="loader"></div>}
                <h1 className="sr-only">{loginHelpUpd}</h1>
                <h1>{loginHelpUpd}</h1>
                <div className="description">{loginHelpSelectActivationMethodUpdated}</div>

                <div>
                    {loginHelpOptions.map((option) => (
                        <div
                            className="row-item"
                            key={option.id}
                            role="presentation"
                            id={option.id}
                            onKeyDown={() => {
                                handleDivChange(option.id);
                            }}
                            onClick={() => {
                                handleDivChange(option.id);
                            }}
                        >
                            <label
                                data-selection={AMPLITUDE_EVENTS.SELECT_BUTTON}
                                htmlFor={option.id}
                            >
                                <div className="radio-button-container">
                                    <input
                                        id={option.id}
                                        data-testid={option.id}
                                        type="radio"
                                        value={option.id}
                                        aria-label={option.title + " " + option.subTitle}
                                        onFocus={(evt) => handleRadioFocus(evt)}
                                        checked={
                                            selectedOption === option.id &&
                                            selectedValue === option.id
                                        }
                                        {...register(`selectedOption`, { required: true })}
                                    />
                                    <span className="option-title">
                                        <b>{option.title}</b>
                                    </span>
                                </div>
                                <p className="option-description">{option.subTitle}</p>
                            </label>
                        </div>
                    ))}
                </div>

                <div className="button-container">
                    <button
                        id="continue"
                        className="btn btn-primary"
                        type="submit"
                        aria-label={button.continue}
                        data-selection={AMPLITUDE_EVENTS.SELECT_BUTTON}
                        onClick={dispatchAmplitudeEvent}
                        disabled={!isValid}
                    >
                        {button.continue}
                    </button>
                </div>
            </form>

            <div className="button-container">
                <button
                    id="cancel"
                    className="cancel-button btn "
                    type="button"
                    aria-label={preference.button.cancel}
                    data-selection={AMPLITUDE_EVENTS.SELECT_BUTTON}
                    onClick={handleCancel}
                >
                    {preference.button.cancel}
                </button>
            </div>
        </div>
    );
};

export default LoginHelpMultiOptions;
