import React, { useState, useCallback, useEffect } from "react";
import authenticatePhone from "../../services/redwoodMfa/authenticatePhone";
import { useTranslations } from "../../queries";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccuCode } from "../../services/accuCodeService";
import challengePhone from "../../services/redwoodMfa/challengePhone";
import challengeSms from "../../services/redwoodMfa/challengeSms";
import { REDWOOD_MFA_SMS } from "../../routes";

interface RedwoodMfaTranslation {
    app: {
        common: {
            labels: {
                cancel: string;
            };
        };
    };
    button: {
        cancel: string;
        continue: string;
    };
    redwoodMfa: {
        phoneAuthenticate: {
            authroizePhone: string;
            callAgain: string;
            callingPhone: string;
            phoneDescription: string;
        };
        redWoodMfaTitle: string;
    };
}
const LandingPhoneRedwoodMfa = () => {
    const [error, setError] = useState<string | null>(null);
    const [successMsg, setSuccessMsg] = useState<string | null>(null);
    const [isPolling, setIsPolling] = useState<boolean>(false);
    const interval = 3000;
    const maxAttempts = 30;
    const csrfToken = window.csrf;
    const accuCode = getAccuCode();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isReact = searchParams.get("isReact");
    const { redwoodMfa, app } = useTranslations<RedwoodMfaTranslation>();

    const startPolling = useCallback(() => {
        let attempts = 0;
        const id = setInterval(async () => {
            try {
                const authData = await authenticatePhone({ csrfToken });
                setIsPolling(true);
                if (authData?.spData?.pullStatus) {
                    setSuccessMsg(authData.spData.pullResultDesc);
                    setIsPolling(false);
                    clearInterval(id);
                    if (
                        authData.spHeader.authLevel === "SESSION_AUTHENTICATED" &&
                        authData.spHeader.status === "ACTIVE"
                    ) {
                        sessionStorage.setItem("userGuidToken", authData.spHeader.userGuid);
                        sessionStorage.setItem("authenticated", authData.spHeader.username);
                        const redirectUrl = "" + "/dashboard/#/home";
                        (window as Window).location = redirectUrl;
                    }
                } else if (authData.spHeader.errors && authData.spHeader.errors.length > 0) {
                    setError(authData.spHeader.errors[0].message);
                    setIsPolling(false);
                    clearInterval(id);
                }

                attempts += 1;
                if (attempts >= maxAttempts) {
                    clearInterval(id);
                }
            } catch (error) {
                console.error("Polling error:", error);
                clearInterval(id);
                setIsPolling(false);
            }
        }, interval);

        return () => clearInterval(id);
    }, [csrfToken, maxAttempts]);

    useEffect(() => {
        const stopPolling = startPolling();
        return () => stopPolling();
    }, [startPolling]);

    const handleCancel = () => {
        window.location.href = `/participant/#/login?accu=${accuCode}`;
    };
    const handleCallAgain = async () => {
        const authData = await challengePhone({ csrfToken });
        if (authData.authLevel === "USER_IDENTIFIED" && authData.status === "ACTIVE") {
            startPolling();
        }
    };
    const handleSmsAuth = async () => {
        const authData = await challengeSms({ csrfToken });
        if (authData.authLevel === "USER_IDENTIFIED" && authData.status === "ACTIVE") {
            navigate(REDWOOD_MFA_SMS);
        }
    };
    return (
        <div>
            <div
                className="container row redwood-mra-container"
                data-testid="landing-redwood-phone-mfa"
            >
                <div className="row">
                    <div
                        className={`registration col-xs-7 ${isReact ? "col-xs-offset-6" : "col-xs-offset-3"}`}
                    >
                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane active">
                                <div className="align-center">
                                    <h1 className="signin-text-color">
                                        {redwoodMfa.redWoodMfaTitle}
                                    </h1>
                                    <p className="signin-text-color">
                                        {redwoodMfa.phoneAuthenticate.phoneDescription}
                                    </p>
                                    {isPolling ? (
                                        <div>
                                            <div className="loader"></div>
                                            <div className="button-group">
                                                <div className="margin-top-default">
                                                    <h2>
                                                        {redwoodMfa.phoneAuthenticate.callingPhone}
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {successMsg && (
                                                <h2 className="text-color-green">{successMsg}</h2>
                                            )}
                                            {error && <h2 className="error-block">{error}</h2>}
                                        </div>
                                    )}

                                    <div className="cancel-btn-align">
                                        <button className="cancel-button" onClick={handleCancel}>
                                            {app.common.labels.cancel}
                                        </button>
                                    </div>

                                    <div className="button-group">
                                        <button
                                            className="resendSmsBtn"
                                            type="button"
                                            onClick={handleCallAgain}
                                        >
                                            {redwoodMfa.phoneAuthenticate.callAgain}
                                        </button>
                                        <button className="resendSmsBtn" onClick={handleSmsAuth}>
                                            {redwoodMfa.phoneAuthenticate.authroizePhone}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LandingPhoneRedwoodMfa;
