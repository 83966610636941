import choosePrimaryPlanTemplate from "./choosePrimaryPlanTemplate";
import linkablePlansTemplate from "./linkablePlansTemplate";
import linkedConfimrationTemplate from "./linkedConfimrationTemplate";
import mfaActivationCodeTemplate from "./mfaActivationCodeTemplate";
import mfaEnterCodeTemplate from "./mfaEnterCodeTemplate";
import noContactInfoModalTemplate from "./noContactInfoModalTemplate";

const OneIdRoute = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.otherwise("/choose-primaryplan");

    $stateProvider
        // IndId Selection View =================================
        .state("choosePrimaryPlan", {
            url: "/choose-primaryplan",
            template: choosePrimaryPlanTemplate,
            controller: "PrimaryPlanSelectionCtrl",
            controllerAs: "PrimaryPlanSelectionCtrl",
            data: {
                fullHeight: true,
                title: "Choose primary plan"
            },
            params: {
                // The header and intro text of the choose primary plan page are determined at runtime by the oneIdFlow param
                // The rest of the text on that page are unchanged between different flows
                // The value can be "login" or "linking"
                // The "login" flow uses the default header and intro, whereas "linking" uses alternate texts that are TBD.
                oneIdFlow: {
                    value: "",
                    squash: true
                }
            }
        })

        .state("noContactInfoLinking", {
            url: "/no-contactinfo-modal",
            template: noContactInfoModalTemplate,
            controller: "LinkablePlansSelectionController",
            controllerAs: "LinkablePlansSelectionController",
            data: {
                fullHeight: true,
                title: "No Contact information"
            }
        })

        .state("oneIdMfaEnterCode", {
            url: "/verifyCode",
            template: mfaEnterCodeTemplate,
            controller: "OneIdMfaController",
            controllerAs: "OneIdMfaController",
            data: {
                fullHeight: true,
                title: "No Contact information"
            }
        })

        .state("oneIdMfaActivationCode", {
            url: "/activationCodeDeliveryOptions",
            template: mfaActivationCodeTemplate,
            controller: "OneIdMfaController",
            controllerAs: "OneIdMfaController",
            data: {
                fullHeight: true,
                title: "No Contact information"
            }
        })

        .state("linkedConfimration", {
            url: "/linked-confirmation",
            template: linkedConfimrationTemplate,
            controller: "LinkedConfirmationController",
            controllerAs: "LinkedConfirmationController",
            data: {
                fullHeight: true,
                title: "Confirmation"
            }
        })

        // comment out controller for react component
        .state("linkablePlans", {
            url: "/linkable-plans",
            template: linkablePlansTemplate,
            //    controller: "LinkablePlansSelectionController",
            //    controllerAs: "LinkablePlansSelectionController",
            data: {
                fullHeight: true,
                title: "Linkable plans"
            }
        })

        // comment out controller for react component
        .state("linkablePlansProfile", {
            url: "/linkable-plans-profile",
            template: linkablePlansTemplate,
            //    controller: "LinkablePlansSelectionController",
            //    controllerAs: "LinkablePlansSelectionController",
            data: {
                fullHeight: true,
                title: "Linkable plans"
            }
        });
};

OneIdRoute.$inject = ["$stateProvider", "$urlRouterProvider"];
export default OneIdRoute;
