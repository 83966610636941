import { executeGet } from "../../../../utils/appUtils";
import ReferenceData from "../../../../constants/ReferenceData";
interface IdProofingQuestionOption {
    id: string;
    option: string;
    selected: boolean;
}

interface IdProofingQuestion {
    displayQuestion: string;
    id: string;
    multiSelect: boolean;
    options: IdProofingQuestionOption[];
}

interface IdProofQuestionData {
    idProofingQuestions: IdProofingQuestion[];
}
const retrieveIDProofQueries = async (): Promise<IdProofQuestionData> => {
    try {
        const response = await executeGet(
            ReferenceData.RETRIVE_IDPROOF_QUESTIONS,
            "retriveIdProofQuestionAccountFault"
        );
        return response.data;
    } catch {
        throw new Error("Failed to fetch ID proofing questions");
    }
};
export default retrieveIDProofQueries;
