import { useContext } from "react";
import { TranslationsServiceContext } from "../../providers/TranslationsServiceProvider";

/*
    Hook to get translations object. Pass nothing to get all translations.
    Pass string of form "key1.key2.key3 ... etc" (e.g. "accountDashboard.investments")
    to get a nested translations object.
 */
export default function useTranslations<T>(keys?: string) {
    let translations = useContext(TranslationsServiceContext);
    if (keys) {
        keys.split(".").forEach((key) => {
            if (translations[key]) {
                translations = translations[key];
            } else {
                console.warn("Key not found on translations object", key);
            }
        });
    }
    return translations as T;
}
