import _findIndex from "lodash/findIndex";
import _isNumber from "lodash/isNumber";
import _isUndefined from "lodash/isUndefined";
import _keys from "lodash/keys";
import localBuildConfig from "../../../../build/buildConfig.json";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import redwoodAuthenticateToken from "../login/services/redwoodAuthenticateToken";
import getRegisterFreemiumToken from "../login/services/getRegisterFreemiumToken";
import loadFtd from "../../app/login/components/loginForm/helpers/loadFtd";

const registrationFreemiumController = function (
    $injector,
    $modal,
    $q,
    $rootScope,
    $scope,
    $state,
    $timeout,
    $translate,
    $location,
    $window
) {
    const logger = ExternalLogger.getInstance("registrationFreemiumController");
    $scope.idToken = $location.search().idToken;
    const multiAuth = localBuildConfig.multiAuth;
    $scope.setPcBaseApiUrl = multiAuth[$window.subdomain];
    $scope.marketingParam = $location.search().marketing_param;

    const handleRewoodDashbaord = async function () {
        //try to get idToken from session or get it from query string in Url.if failed redirect to loginpage
        try {
            const getIdTokenResponse = await getRegisterFreemiumToken($scope.setPcBaseApiUrl);
            $scope.getidToken = getIdTokenResponse.spHeader.idToken;
        } catch (error) {
            logger.error("ERROR - getRegisterFreemiumToken api: ", error);
        }
        if ($scope.getidToken) {
            const redwoodResponse = await redwoodAuthenticateToken(
                $scope.setPcBaseApiUrl,
                $scope.getidToken
            );
            loadFtd(redwoodResponse, null, $state);
        } else if ($scope.idToken) {
            const redwoodResponse = await redwoodAuthenticateToken(
                $scope.setPcBaseApiUrl,
                $scope.idToken
            );
            sessionStorage.setItem("marketingParam", $scope.marketingParam);
            loadFtd(redwoodResponse, null, $state);
        } else {
            const loginPageUrl = "participant/#/login";
            $window.location.href = "../../" + loginPageUrl;
        }
    };

    handleRewoodDashbaord();
};

registrationFreemiumController.$inject = [
    "$injector",
    "$modal",
    "$q",
    "$rootScope",
    "$scope",
    "$state",
    "$timeout",
    "$translate",
    "$location",
    "$window"
];

export default registrationFreemiumController;
