import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../queries/constants";
import { retrievePlansAvailableForLinkingProfile } from "../../services/oneId/linkablePlansService";

const useLinkablePlansProfile = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_LINKABLE_PLANS_PROFILE],
        queryFn: async () => {
            const data = await retrievePlansAvailableForLinkingProfile();
            return data;
        }
    });
};

export default useLinkablePlansProfile;
