import otsAccountRegistrationDetailsFormTemplate from "../accountOpen/otsAccountRegistrationDetailsFormTemplate";
import otsAccountRegistrationPersonalDetailsFormTemplate from "../accountOpen/otsAccountRegistrationPersonalDetailsFormTemplate";

import indexTemplate from "./indexTemplate";

import iraPreAccountOpeningTemplate from "./iraPreAccountOpeningTemplate";
import loginHelpLetsTryElseTemplate from "./loginHelpLetsTryElseTemplate";
import regErrorTemplate from "./regErrorTemplate";
import registrationGroupPasswordTemplate from "./registrationGroupPasswordTemplate";
import registrationSummaryTemplate from "./registrationSummaryTemplate";
import registrationFreemiumTemplate from "./registeFreemiumTemplate";
import registrationWithoutPinTemplate from "./registrationWithoutPinTemplate";
import registrationWithPinTemplate from "./registrationWithPinTemplate";

const registrationRoute = function ($stateProvider, $urlRouterProvider) {
    $urlRouterProvider.when("/register", "/register/");

    $stateProvider

        // registration parent view =================================
        .state("register", {
            url: "/register?accu",
            abstract: true,
            template: indexTemplate,
            controller: "registrationController",
            data: {
                fullHeight: true,
                title: "Registration account lookup"
            }
        })

        // registration freemium dashboard redirect =================================
        .state("registerFreemium", {
            url: "/register/freemium?idToken",
            template: registrationFreemiumTemplate,
            controller: "registrationFreemiumController",
            data: {
                fullHeight: true,
                title: "Registration freemium dashboard redirect"
            }
        })

        // registration with  pin View =================================
        // registerWithPin
        .state("register.withPin", {
            url: "/pin",
            template: registrationWithPinTemplate,
            controller: "registrationController",
            data: {
                fullHeight: true
            }
        })

        // registration with  Group Password =================================
        // registerWithGroupPassword
        .state("register.withGroupPassword", {
            url: "/group",
            template: registrationGroupPasswordTemplate,
            controller: "registrationWithGroupPasswordController",
            data: {
                fullHeight: true,
                title: "Registration account lookup"
            }
        })

        .state("registrationHardStopError", {
            url: "/registration/regError",
            template: regErrorTemplate,
            controller: "registrationController",
            data: {
                fullHeight: true,
                title: "Registration Error"
            },
            params: {
                hardStopError: {
                    value: "true",
                    squash: true
                }
            }
        })

        // registration summary =================================
        .state("registrationSummary", {
            url: "/registration-summary",
            template: registrationSummaryTemplate,
            controller: "registrationSummaryController",
            controllerAs: "vm",
            data: {
                fullHeight: true,
                title: "Registration Summary"
            },
            resolve: {
                groupId: [
                    "$q",
                    "primaryPlanService",
                    function ($q, primaryPlanService) {
                        const deferred = $q.defer();

                        primaryPlanService.getPrimaryPlan().then(
                            function (primaryPlan) {
                                deferred.resolve(primaryPlan.gaId);
                            },
                            function () {
                                deferred.reject();
                            }
                        );

                        return deferred.promise;
                    }
                ]
            }
        })
        .state("preAccountOpening", {
            url: "/open-account-registration",
            template: iraPreAccountOpeningTemplate,
            controller: "preAccountOpeningController",
            controllerAs: "paoCtl"
        })
        // registration without pin View =================================
        // registerWithoutPin
        .state("register.withoutPin", {
            url: "/:flowName",
            template: registrationWithoutPinTemplate,
            controller: "registrationController",
            data: {
                fullHeight: true
            }
        })
        .state("loginHelpLetsTryElse", {
            url: "/loginHelpLetsTryElse?accu",
            template: loginHelpLetsTryElseTemplate,
            controller: "registrationController",
            data: {
                fullHeight: true,
                title: "Login Help"
            }
        })
        .state("otsAccountRegistration", {
            url: "/accountOpenDetails",
            template: otsAccountRegistrationDetailsFormTemplate,
            controller: "accountOpenController",
            params: {
                formData: null
            }
        })

        .state("otsAccountRegistrationB", {
            url: "/accountOpenPersonalDetails",
            template: otsAccountRegistrationPersonalDetailsFormTemplate,
            controller: "accountOpenController",
            params: {
                formData: null,
                skipExperian: false
            }
        });
};

registrationRoute.$inject = ["$stateProvider", "$urlRouterProvider"];
export default registrationRoute;
