import ReferenceData from "../constants/ReferenceData";
import axios from "axios";
export const getParticipantDataAsync = async () => {
    const getParticipantDataAsyncSuccess = (results) => {
        if (results) {
            return results;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.PARTICIPANT_LOGIN_INFORMATON;
    return axios
        .get(liveUrl)
        .then(getParticipantDataAsyncSuccess)
        .catch((err) => {
            throw err;
        });
};

export const getlogBrowserInfo = async () => {
    const getlogBrowserInfoSuccess = (results) => {
        if (results) {
            return results;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.PARTICIPANT_LOGIN_LOG_BROWSER_INFO;
    return axios
        .get(liveUrl)
        .then(getlogBrowserInfoSuccess)
        .catch((err) => {
            throw err;
        });
};

export const getRouteDetermination = async () => {
    const getRouteDeterminationSuccess = (results) => {
        if (results) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.PARTICIPANT_LOGIN_ROUTE_DETERMINATION;

    return axios
        .get(liveUrl)
        .then(getRouteDeterminationSuccess)
        .catch((err) => {
            throw err;
        });
};
