import React, { FocusEvent, useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import {
    useTranslations,
    useDefaultCustomizationRtr,
    usePreLoginAccuRetrieval
} from "../../../queries";
import {
    formatDate,
    formatSSN,
    formatZipCode,
    validSSN,
    validId,
    stripNonAlphanumeric
} from "../../../utils/formUtils";
import DateUtil from "core-ui/client/src/app/DateUtil";
import ControlledFormField from "../../shared/controlledFormField/ControlledFormField";
import { stripNonDigits } from "../../../utils/formUtils/stripNonDigits";
import { getAccuCode } from "../../../services/accuCodeService";
import registerWithoutPin from "../../../services/register/registerWithoutPin";
import useRedirect from "../../../hooks/useRedirect";
import handleRegisterServiceError from "../../../services/register/handleRegisterServiceError";
import {
    LOGIN_HELP_LETS_TRY_ELSE,
    LOGIN_HELP_NO_RECORD,
    LOGIN_HELP_OPTIONS
} from "../../../routes";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import AccountDefaultCustom from "../../../types/AccountDefaultCustom";
import { DEFAULT_CUSTOMIZATIONS } from "./types";

interface RegisterWithoutPinTranslations {
    app: { beneficiary: { bene: { validationMessages: { socialSecurityNumberInvalid: string } } } };
    button: { continue: string };
    dateOfBirthFutureDateNotAllowed: string;
    dateOfBirthInvalid: string;
    dateOfBirthRequired: string;
    dateOfBirthYearOutOfRange: string;
    idProofingEnabled: string;
    individual: {
        dateOfBirth: string;
        planID: string;
        ssn: string;
        uniqueID: string;
        zipCode: string;
    };
    loginHelpFindAccount: string;
    logon: { [key: string]: string };
    planIDRequired: string;
    planIdNumeric: string;
    preference: {
        button: {
            back: string;
        };
    };
    ssnRequired: string;
    tellUsAboutYou: string;
    uniqueIdNumeric: string;
    uniqueIdentifierRequired: string;
    zipcodeRequired: string;
}

interface RegisterWithoutPinProps {
    flowName: string;
    location?: { path: (path: string) => void };
    scope?: { $apply: () => void; $root: { featureName: string } };
}

interface RegisterWithoutPinFields {
    dateOfBirth: string;
    planId: string;
    ssn: string;
    uniqueId: string;
    zipCode: string;
}

const DEFAULT_VALUES = {
    ssn: "",
    zipCode: "",
    dateOfBirth: "",
    uniqueId: "",
    planId: ""
};

const RegisterWithoutPin = ({ flowName, location, scope }: RegisterWithoutPinProps) => {
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting }
    } = useForm<RegisterWithoutPinFields>({
        defaultValues: DEFAULT_VALUES,
        mode: "onBlur",
        criteriaMode: "all"
    });
    const {
        app,
        button,
        dateOfBirthFutureDateNotAllowed,
        dateOfBirthInvalid,
        dateOfBirthRequired,
        dateOfBirthYearOutOfRange,
        idProofingEnabled,
        individual,
        loginHelpFindAccount,
        logon: errorMessages,
        planIdNumeric,
        planIDRequired,
        preference,
        ssnRequired,
        tellUsAboutYou,
        uniqueIdNumeric,
        uniqueIdentifierRequired,
        zipcodeRequired
    } = useTranslations<RegisterWithoutPinTranslations>();

    const {
        data: defaultCustomRtrResults,
        isLoading: defaultCustomRtrLoading,
        isError: isDefaultCustomRtrError,
        error: defaultCustomRtrError
    } = useDefaultCustomizationRtr();

    const [typeSSN, setTypeSSN] = useState("text");
    const [typeUniqueId, setTypeUniqueId] = useState("text");
    const [typePlanId, setTypePlanId] = useState("text");
    const [typeDateOfBirth, setTypeDateOfBirth] = useState("text");
    const redirect = useRedirect();
    const navigate = useNavigate();
    const accuCode = getAccuCode();
    const { state } = useLocation();

    const displayContinueBtn = state?.type !== "affiliate" && state?.type !== "freemium";
    const displayContinueVal = displayContinueBtn ? button?.continue : loginHelpFindAccount;
    const [combinedCustomizations, setCombinedCustomizations] =
        useState<AccountDefaultCustom>(DEFAULT_CUSTOMIZATIONS);

    const {
        data: preLoginAccuRetrievalResults,
        isLoading: preLoginAccuRetrievalLoading,
        isError: isPreLoginAccuRetrievalError,
        error: preLoginAccuRetrievalError
    } = usePreLoginAccuRetrieval(accuCode);

    /**
     * The following defines the validations for each field. Each validation must return undefined
     * if the rule is not violated in order to remove it from the field's error object.
     */
    const SSN_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? ssnRequired : undefined),
            isValid: (value: string) =>
                !validSSN(value)
                    ? app.beneficiary.bene.validationMessages.socialSecurityNumberInvalid
                    : undefined
        }
    };
    const ZIP_CODE_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? zipcodeRequired : undefined)
        }
    };
    const UNIQUE_ID_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? uniqueIdentifierRequired : undefined),
            isValid: (value: string) => (!validId(value) ? uniqueIdNumeric : undefined)
        }
    };
    const PLAN_ID_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? planIDRequired : undefined),
            isValid: (value: string) => (!validId(value) ? planIdNumeric : undefined)
        }
    };
    const DATE_OF_BIRTH_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? dateOfBirthRequired : undefined),
            isValid: (value: string) =>
                !DateUtil.isValid(value, "MM/DD/YYYY", true) ? dateOfBirthInvalid : undefined,
            isOutOfRange: (value: string) =>
                DateUtil.isBefore(value, DateUtil.getMinimumDateOfBirth())
                    ? dateOfBirthYearOutOfRange
                    : undefined,
            isFutureDate: (value: string) =>
                DateUtil.isAfter(value, DateUtil.getDate())
                    ? dateOfBirthFutureDateNotAllowed
                    : undefined
        }
    };

    useEffect(() => {
        document.getElementById("ssn")?.focus();
    }, []);

    useEffect(() => {
        if (
            !isDefaultCustomRtrError &&
            !defaultCustomRtrLoading &&
            defaultCustomRtrResults &&
            !isPreLoginAccuRetrievalError &&
            !preLoginAccuRetrievalLoading &&
            preLoginAccuRetrievalResults
        ) {
            const tempCombinedCustomizations = {
                ...defaultCustomRtrResults,
                ...preLoginAccuRetrievalResults
            };
            // eslint-disable-next-line
            setCombinedCustomizations(tempCombinedCustomizations);
        }
    }, [
        defaultCustomRtrResults,
        defaultCustomRtrLoading,
        isDefaultCustomRtrError,
        preLoginAccuRetrievalResults,
        preLoginAccuRetrievalLoading,
        isPreLoginAccuRetrievalError
    ]);

    /**
     * When the SSN field loses focus, validate the value. If it is valid, set the field type to
     * 'password' to conceal the sensitive information.
     */
    const handleBlurSSN = (event: FocusEvent<HTMLInputElement, Element>) => {
        if (validSSN(event.target.value)) {
            setTypeSSN("password");
        }
    };

    /**
     * When the SSN field gains focus, set the field type to 'text' so the user can see the value
     * in case they need to make an edit.
     */
    const handleFocusSSN = () => {
        setTypeSSN("text");
    };

    const handleBlurUniqueId = (event: FocusEvent<HTMLInputElement, Element>) => {
        if (validId(event.target.value)) {
            setTypeUniqueId("password");
        }
    };

    const handleFocusUniqueId = () => {
        setTypeUniqueId("text");
    };

    const handleBlurPlanId = (event: FocusEvent<HTMLInputElement, Element>) => {
        if (validId(event.target.value)) {
            setTypePlanId("password");
        }
    };

    const handleFocusPlanId = () => {
        setTypePlanId("text");
    };

    /**
     * When the Date of Birth field loses focus, validate the value. If it is valid, set the field type to
     * 'password' to conceal the sensitive information.
     */
    const handleBlurDateOfBirth = (event: FocusEvent<HTMLInputElement, Element>) => {
        if (DateUtil.isValid(event.target.value, "MM/DD/YYYY", true)) {
            setTypeDateOfBirth("password");
        }
    };

    /**
     * When the Date of Birth field gains focus, set the field type to 'text' so the user can see the value
     * in case they need to make an edit.
     */
    const handleFocusDateOfBirth = () => {
        setTypeDateOfBirth("text");
    };

    const updateAngularRoutingState = useCallback(
        (destination) => {
            /**
             * TODO: Delete this function when Angular is removed. Since we are using the React router
             * we need to update the Angular routing state to match the new React routing state.
             */
            if (location && scope) {
                location.path(destination);
                scope.$apply();
            }
        },
        [location, scope]
    );

    const handleInvalid = () => {
        setError("root", { message: "Error has occurred" });
    };

    const onSubmit = async (formData: RegisterWithoutPinFields) => {
        const { dateOfBirth, ssn, zipCode, uniqueId, planId } = formData;
        const payload = combinedCustomizations.showLoginSetupRecoveryRadio
            ? {
                  flowName: flowName,
                  accu: getAccuCode(),
                  dateOfBirth: flowName === "loginHelp" ? dateOfBirth : stripNonDigits(dateOfBirth),
                  isIDProofing: idProofingEnabled,
                  lastName: "",
                  uniqueId: uniqueId,
                  metrixPlanId: planId
              }
            : {
                  flowName: flowName,
                  accu: getAccuCode(),
                  dateOfBirth: flowName === "loginHelp" ? dateOfBirth : stripNonDigits(dateOfBirth),
                  isIDProofing: idProofingEnabled,
                  lastName: "",
                  ssn: stripNonDigits(ssn),
                  zipcode: zipCode
              };

        try {
            const { data } = await registerWithoutPin(payload);
            if (data.state === "ID_PROOFING_CONSENT") {
                // TODO (Maja): Trigger ID Proof Consent Modal
                // Dependency on WCDX-21096
            } else {
                redirect(data);
            }
        } catch (err) {
            if (
                flowName === "loginHelp" &&
                (accuCode === "Empower" || accuCode === "EmpowerStaff" || accuCode === "MYERIRA")
            ) {
                if (state?.failedFreemium === "Y") {
                    navigate("/" + LOGIN_HELP_NO_RECORD, {
                        replace: true,
                        state: {
                            type: "both",
                            failedAffiliate: "Y",
                            failedFreemium: state?.failedFreemium
                        }
                    });
                } else {
                    navigate("/" + LOGIN_HELP_LETS_TRY_ELSE, {
                        replace: true,
                        state: {
                            type: "affiliate",
                            failedAffiliate: "Y",
                            failedFreemium: state?.failedFreemium
                        }
                    });

                    updateAngularRoutingState(LOGIN_HELP_LETS_TRY_ELSE);
                }
            } else {
                const message = handleRegisterServiceError(errorMessages, err);
                setError("root", { message });
            }
        }
    };

    const handleBack = (e) => {
        //eslint-disable-next-line
        if (
            !String(e.target["id"]).toLowerCase().includes("back") &&
            !String(e.target["id"]).toLowerCase().includes("backbtn")
        ) {
            return;
        }

        navigate("/" + LOGIN_HELP_OPTIONS + "?accu=" + accuCode, { replace: true });
        e.preventDefault();
        return false;
    };

    if (isDefaultCustomRtrError) {
        console.error("ERROR - useDefaultCustomizationRtr: ", String(defaultCustomRtrError));
        return null;
    }

    if (isPreLoginAccuRetrievalError) {
        console.error("ERROR - usePreLoginAccuRetrieval: ", String(preLoginAccuRetrievalError));
        return null;
    }

    return (
        <>
            <form
                data-testid="register-without-pin"
                onSubmit={handleSubmit(onSubmit, handleInvalid)}
            >
                {flowName === "loginHelp" &&
                    (accuCode === "Empower" ||
                        accuCode === "EmpowerStaff" ||
                        accuCode === "MYERIRA") &&
                    !displayContinueBtn && <h1 className="loginHelpHeader">{tellUsAboutYou}</h1>}
                {isSubmitting && (
                    <div className="loaderBackground">
                        <div className="loader"></div>
                    </div>
                )}
                {errors?.root?.message && (
                    <div
                        data-testid="register-without-pin-error"
                        className="error-block margin-bottom-100"
                        aria-live="polite"
                    >
                        {errors?.root?.message}
                    </div>
                )}
                {combinedCustomizations.showLoginSetupRecoveryRadio ? (
                    <>
                        <ControlledFormField
                            className="col-5"
                            control={control}
                            id="unique-id"
                            label={individual?.uniqueID}
                            name="uniqueId"
                            maxLength={10}
                            onChange={stripNonAlphanumeric}
                            onBlur={handleBlurUniqueId}
                            onFocus={handleFocusUniqueId}
                            rules={UNIQUE_ID_RULES}
                            type={typeUniqueId}
                            extraAriaDescribedBy="unique-id-error-isRequired unique-id-error-isValid"
                        />
                        <ControlledFormField
                            className="col-5"
                            control={control}
                            id="plan-id"
                            label={individual?.planID}
                            maxLength={12}
                            name="planId"
                            onBlur={handleBlurPlanId}
                            onFocus={handleFocusPlanId}
                            onChange={stripNonAlphanumeric}
                            rules={PLAN_ID_RULES}
                            type={typePlanId}
                            extraAriaDescribedBy="plan-id-error-isRequired plan-id-error-isValid"
                        />
                    </>
                ) : (
                    <>
                        <ControlledFormField
                            className="col-5"
                            control={control}
                            id="ssn"
                            label={individual?.ssn}
                            name="ssn"
                            onBlur={handleBlurSSN}
                            onChange={formatSSN}
                            onFocus={handleFocusSSN}
                            rules={SSN_RULES}
                            type={typeSSN}
                            extraAriaDescribedBy="ssn-error-isRequired ssn-error-isValid"
                        />
                        <ControlledFormField
                            className="col-5"
                            control={control}
                            id="zip-code"
                            label={individual?.zipCode}
                            maxLength={10}
                            name="zipCode"
                            onChange={formatZipCode}
                            rules={ZIP_CODE_RULES}
                            extraAriaDescribedBy="zip-code-error-isRequired"
                        />
                    </>
                )}
                <ControlledFormField
                    control={control}
                    id="date-of-birth"
                    label={individual?.dateOfBirth}
                    name="dateOfBirth"
                    onBlur={handleBlurDateOfBirth}
                    onChange={formatDate}
                    onFocus={handleFocusDateOfBirth}
                    rules={DATE_OF_BIRTH_RULES}
                    type={typeDateOfBirth}
                    extraAriaDescribedBy="date-of-birth-error-isRequired date-of-birth-error-isValid"
                />
                <div className="form-group">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg btn-block margin-top-default outline-btn"
                        id="login-help-submit"
                        data-testid="login-help-submit"
                    >
                        {flowName === "loginHelp" &&
                        (accuCode === "Empower" ||
                            accuCode === "EmpowerStaff" ||
                            accuCode === "MYERIRA") ? (
                            <span id="submitBtn" className="loginHelpLabel">
                                {displayContinueVal}
                            </span>
                        ) : (
                            <span id="submitBtn">{button?.continue?.toUpperCase()}</span>
                        )}
                    </button>
                </div>
            </form>

            {!displayContinueBtn && (
                <div className="button-container">
                    <button
                        id="back"
                        className="cancel-button btn btn-lg btn-block outline-btn"
                        type="button"
                        aria-label={"back"}
                        data-selection={AMPLITUDE_EVENTS.SELECT_BUTTON}
                        onClick={(e) => handleBack(e)}
                    >
                        <span id="backBtn" className="loginHelpLabel">
                            {preference?.button?.back}
                        </span>
                    </button>
                </div>
            )}
        </>
    );
};

export default RegisterWithoutPin;
