import React, { useState } from "react";
import { useForm } from "react-hook-form";
import type { FieldValues } from "react-hook-form";
import { useTranslations } from "../../../queries";
import ControlledFormField from "../../shared/controlledFormField/ControlledFormField";
interface IdProofingWithPinTranslations {
    app: { beneficiary: { bene: { validationMessages: { socialSecurityNumberInvalid: string } } } };
    button: { continue: string };
    individual: {
        pin: string;
        ssn: string;
    };
    pinRequired: string;
}

const DEFAULT_VALUES = {
    pin: ""
};

type PinType = "number" | "password";

interface RegisterTranslations {
    enterAccountInfo: string;
    enterPin: string;
    enterPinDesc: string;
}
const IdProofingWithPinComponent = () => {
    const { control } = useForm<FieldValues>({
        defaultValues: DEFAULT_VALUES,
        mode: "onBlur"
    });

    const [typePin, setTypePin] = useState<PinType>("number");

    const { pinRequired, individual, button } = useTranslations<IdProofingWithPinTranslations>();
    const { enterPin, enterPinDesc } = useTranslations<RegisterTranslations>();

    const PIN_RULES = {
        validate: {
            isRequired: (value: string) => (!value ? pinRequired : undefined),
            isConfirmLength: (value: string) =>
                value.length < 4 || value.length > 16
                    ? "PIN must be between 4 to 16 digit"
                    : undefined
        }
    };

    const handleBlurPin = () => {
        setTypePin("password");
    };

    const handleFocusPin = () => {
        setTypePin("number");
    };

    return (
        <div className="container row" data-testid="id-proofing-with-pin-component">
            <div className="row">
                <div className="registration col-xs-6 col-xs-offset-5 container-pad">
                    <header className="contained-form-header margin-top-default">
                        <h1>{enterPin}</h1>
                    </header>

                    <div className="tab-content hidden-xs">
                        <div role="tabpanel" className="tab-pane active">
                            <p>{enterPinDesc}</p>
                            <form data-testid="register-with-pin">
                                <ControlledFormField
                                    className="col-5"
                                    control={control}
                                    id="pin"
                                    label={individual.pin || ""}
                                    maxLength={16}
                                    name="pin"
                                    onBlur={handleBlurPin}
                                    onFocus={handleFocusPin}
                                    rules={PIN_RULES}
                                    type={typePin}
                                    extraAriaDescribedBy="pin-error-isRequired pin-error-isValid"
                                />
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-lg btn-block margin-top-default outline-btn"
                                        id="login-help-submit"
                                    >
                                        <span>{button.continue.toUpperCase()}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default IdProofingWithPinComponent;
