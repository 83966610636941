import React, { useState } from "react";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { isMobileDevice } from "core-ui/client/src/app/core/util/Devices";

import LoginFormContainerNative from "../components/loginForm/container/LoginFormNativeContainer";
import { getAccuCode } from "../services/accuCodeService";
import { TemplatePreLoginBody, clearLanguageCookies, retrievePreLoginBulletin } from "./helpers";
import {
    useAccountDetails,
    useAccuService,
    useAuthData,
    useDefaultCustomizationRtr,
    usePreLoginAccuRetrieval
} from "../queries";
import AccuCustomization from "../types/AccuCustomization";

import { sentryLoginAppStartMetrics } from "../../utils/sentryMetricsLogging";
import { BulletinItem } from "../types/PreLoginBulletin";
import PreLoginBulletin from "../components/authentication/PreLoginBulletin";
import TemplateBlockingMobile from "../container/helpers/TemplateBlockingMobile";

interface LandingLoginContainerProps {
    isSSOLogin: boolean;
}

const LandingLoginContainer = ({ isSSOLogin }: LandingLoginContainerProps) => {
    /** * Logger. */
    const logger = ExternalLogger.getInstance("LandingLoginContainer");

    const [accuCode, setAccuCode] = useState<string>("");
    const [authCalled, setAuthCalled] = useState(false);
    const [accuCustomization, setAccuCustomization] = useState<AccuCustomization | null>(null);
    const [bulletin, setBulletin] = useState([] as BulletinItem[]);
    const [showBlockingView, setShowBlockingView] = useState(false);
    const [blockingMobileDevices, setBlockingMobileDevices] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    // landing on /login is when we want to log the login start time
    sentryLoginAppStartMetrics();

    const {
        data: authDataResults,
        isLoading: authDataLoading,
        isError: isAuthDataError,
        error: authDataError
    } = useAuthData();

    const {
        data: accDetailsResults,
        isLoading: accDetailsLoading,
        isError: isAccDetailsError,
        error: accDetailsError
    } = useAccountDetails(authDataResults);

    logger.debug("Try to clear cookies...");
    const accu = getAccuCode();

    const currentAccu = accuCode ? accuCode : accu;
    if (accuCode !== accu) {
        setAccuCode(String(accu));
    }

    // Clear spanish cookies, clear language cookies, and set new cookies; see helpers/clearLanguageCookies.js
    clearLanguageCookies(currentAccu, logger);

    // set if !ssoLogin, set accu in setAccuService
    const { isError: isAccuServiceError, error: accuServiceError } = useAccuService(
        currentAccu,
        isSSOLogin
    );

    const {
        data: defaultCustomRtrResults,
        isLoading: defaultCustomRtrLoading,
        isError: isDefaultCustomRtrError,
        error: defaultCustomRtrError
    } = useDefaultCustomizationRtr();

    const {
        data: preLoginAccuRetrievalResults,
        isLoading: preLoginAccuRetrievalLoading,
        isError: isPreLoginAccuRetrievalError,
        error: preLoginAccuRetrievalError
    } = usePreLoginAccuRetrieval(currentAccu);

    const renderPreLoginBodyTemplate = (accuCustomization) => {
        if (accuCustomization) {
            return (
                <div>
                    <TemplatePreLoginBody
                        styleEnv={__styleEnv__}
                        accuCustomization={accuCustomization}
                    />
                </div>
            );
        }
    };

    const renderMobileBlockingTemplate = (accuCustomization) => {
        if (accuCustomization && accuCustomization.blockingMobileTemplate) {
            return (
                <TemplateBlockingMobile
                    styleEnv={__styleEnv__}
                    accuCustomization={accuCustomization}
                />
            );
        }
    };

    const callPreLoginBulletin = async (currentAccu, primaryDB, logger) => {
        const bulletinData = await retrievePreLoginBulletin(currentAccu, primaryDB, logger);
        setBulletin(bulletinData);
    };

    const getSearchParams = (url) => (url.hash.includes("?") ? url.hash.split("?")[1] : null);

    const hasBlockingParam = (params) => params && params.includes("blocking");

    const getBlockingParamValue = (params) => params.match(/blocking=\w+/)[0].split("=")[1];

    const forceBlockingMobileDevices = (value) => {
        setBlockingMobileDevices(value === "true");
    };

    const overrideBlockingMobileDevices = () => {
        const searchParams = getSearchParams(window.location);

        if (hasBlockingParam(searchParams)) {
            forceBlockingMobileDevices(getBlockingParamValue(searchParams));
        }
    };

    if (!authCalled) {
        if (!authDataLoading && !isAuthDataError) {
            logger.debug("authDataResults {0}", [JSON.stringify(authDataResults)]);

            setAuthCalled(true);
            logger.debug("container - authenticated {0}", [
                JSON.stringify(authDataResults?.authenticated)
            ]);

            if (!authDataResults?.authenticated) {
                logger.debug("not authenticated...");
                const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
                if (urlParams.has("accu")) {
                    const pageAccu = urlParams.get("accu");
                    window.accu = pageAccu ? pageAccu : "";
                    setAccuCode(String(pageAccu));
                }
            }

            if (!accDetailsLoading && !isAccDetailsError && authDataResults?.authenticated) {
                window.accu =
                    accDetailsResults && accDetailsResults.accuCode
                        ? accDetailsResults.accuCode
                        : window.accu;
                setAccuCode(window.accu);
            }

            // Clear duplicate cookies
            const currentUrl = window.location.href;

            if (
                currentUrl.indexOf("participant") >= 0 &&
                (currentUrl.indexOf("login") >= 0 || currentUrl.indexOf("signin") >= 0)
            ) {
                if (
                    !defaultCustomRtrError &&
                    !preLoginAccuRetrievalError &&
                    !defaultCustomRtrLoading &&
                    !preLoginAccuRetrievalLoading
                ) {
                    if (!defaultCustomRtrResults) return;
                    if (!preLoginAccuRetrievalResults) return;
                    sessionStorage.setItem("accuFaviconPng", defaultCustomRtrResults.faviconPng);

                    if (
                        preLoginAccuRetrievalResults &&
                        preLoginAccuRetrievalResults.blockingMobileDevices
                    ) {
                        overrideBlockingMobileDevices();

                        setBlockingMobileDevices(
                            preLoginAccuRetrievalResults.blockingMobileDevices
                        );

                        setIsMobile(isMobileDevice());

                        if (blockingMobileDevices && isMobile) {
                            //&& !isOauth   Tod isOauth
                            const siteHeader = document.querySelector(".site-header");

                            if (siteHeader) {
                                siteHeader.classList.add("is-mobile");
                            }

                            const isArticle = String(window.location.href).indexOf("articles") > -1;
                            const isInvestmentInformation =
                                String(window.location.href).indexOf("investmentInformation") > -1;
                            setShowBlockingView(!(isArticle || isInvestmentInformation));
                        } else {
                            setBlockingMobileDevices(false);
                            setIsMobile(false);
                            setShowBlockingView(false);
                        }
                    }
                    const combinedCustomizations = {
                        ...defaultCustomRtrResults,
                        ...preLoginAccuRetrievalResults
                    };
                    const event = new CustomEvent("accu:updated");
                    document.dispatchEvent(event);

                    setAccuCustomization(combinedCustomizations);
                    globalThis.accuCustomization = combinedCustomizations;

                    callPreLoginBulletin(
                        window.accu,
                        combinedCustomizations.primaryDatabase.preLoginPrimaryDB,
                        logger
                    );
                }
            }
        }
    }

    if (accDetailsLoading || authDataLoading || defaultCustomRtrLoading) {
        return <div id="spinner" className="loader"></div>;
    }

    if (isAuthDataError) {
        console.error("Get Auth Error: ", authDataError);
        return null;
    }

    if (isAccDetailsError) {
        console.error("ERROR -  useAccDetails: ", String(accDetailsError));
        return null;
    }

    if (isAccuServiceError) {
        console.error("ERROR -  useAccuService: ", String(accuServiceError));
        return null;
    }

    if (isDefaultCustomRtrError) {
        console.error("ERROR - useDefaultCustomizationRtr: ", String(defaultCustomRtrError));
        return null;
    }

    if (isPreLoginAccuRetrievalError) {
        console.error("ERROR - usePreLoginAccuRetrieval: ", String(preLoginAccuRetrievalError));
        return null;
    }

    return (
        <div className="container with-top-margin">
            <div className="container row">
                <div className="row">
                    <LoginFormContainerNative
                        accuCodeIn={accuCode}
                        fromIn={""}
                        isLoginPartIn={accuCustomization?.isLoginPart}
                    />

                    <div id="preLoginbody" className="col-xs-12 col-sm-9 col-sm-pull-3 showLogin">
                        {accuCustomization && accuCustomization.preLoginBodyTemplate
                            ? renderPreLoginBodyTemplate(accuCustomization)
                            : null}
                    </div>

                    <div>
                        {accuCustomization &&
                            accuCustomization.blockingMobileTemplate &&
                            showBlockingView &&
                            renderMobileBlockingTemplate(accuCustomization)}
                    </div>
                </div>
            </div>
            {bulletin && bulletin.length > 0 ? (
                <PreLoginBulletin bulletins={bulletin}></PreLoginBulletin>
            ) : null}
        </div>
    );
};

export default LandingLoginContainer;
