import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslations } from "../../../queries";
import RegisterWithoutPin from "../../register/registerWithoutPin/RegisterWithoutPin";

interface LoginHelpTranslations {
    enterResetInfo: string;
    loginHelp: string;
}

interface LoginHelpProps {
    accu?: string;
    location?: { path: (path: string) => void };
    scope?: { $apply: () => void; $root: { featureName: string } };
}

const FLOW_NAME = "loginHelp";

const LoginHelp = ({ location, scope }: LoginHelpProps) => {
    const { loginHelp, enterResetInfo } = useTranslations<LoginHelpTranslations>();
    // eslint-disable-next-line
    const { state } = useLocation();
    const shouldDisplayHeader = state?.type !== "affiliate" && state?.type !== "freemium";

    return (
        <div className="container">
            <div className="container row">
                <div className="row">
                    <div className="registration col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                        {shouldDisplayHeader && (
                            <header className="contained-form-header margin-top-default">
                                <h1>
                                    {loginHelp}
                                    <span className="registration-progress">(1 of 4)</span>
                                </h1>
                                <p>{enterResetInfo}</p>
                            </header>
                        )}

                        <div className="inner-container with-padding with-shadow">
                            <RegisterWithoutPin
                                flowName={FLOW_NAME}
                                location={location}
                                scope={scope}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginHelp;
