// Constants
const KEY_CODE_TAB = 9,
    KEY_CODE_BACKSPACE = 8;

/**
 * The module that will contain our validation directives. AngularJS provides $validators
 * to make custom validation easy.
 *
 * @type {*|module}
 */

// eslint-disable-next-line no-undef
const validationDirectives = angular.module("validationDirectives", []);

/**************** Directives for Registration Without Pin************************/

/***** Regex directive for international zipcode***********/

/**
 * Extends the AngularJS $validators to validate an input field for international zipcode
 *
 * Usage:
 * <input type="text" name="zipcode" data-ng-model="zipcode" reg-ex-input />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */

const regExInput = function () {
    "use strict";
    return {
        restrict: "A",
        require: "?regEx",
        scope: {},
        replace: false,
        link: function (scope, element, attrs) {
            element.bind("keypress", function (event) {
                const regex = new RegExp(attrs.regEx);
                const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);

                // Allow TAB and BACKSPACE
                // keyCode should be used instead of charCode for non-printable keys
                if (event.keyCode === KEY_CODE_TAB || event.keyCode === KEY_CODE_BACKSPACE) {
                    return true;
                }

                if (!regex.test(key)) {
                    event.preventDefault();
                    return false;
                }
            });
        }
    };
};
validationDirectives.directive("regExInput", regExInput);

/***** SSN Length Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for a nine-digit
 * Social Security Number with no punctuation.
 *
 * Usage:
 * <input type="text" name="ssn" data-ng-model="individual.ssn" data-ssn-length-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const ssnLengthValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^\d{9}$/;

            ctrl.$validators.ssnLength = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("ssnLengthValidator", ssnLengthValidator);

/***** SSN Digits Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for numeric value only.
 *
 * Usage:
 * <input type="text" name="ssn" data-ng-model="myModel.ssn" data-ssn-digits-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const ssnDigitsValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^[0-9]*$/;

            ctrl.$validators.ssnDigits = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("ssnDigitsValidator", ssnDigitsValidator);

/***** Zipcode Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for a five-digit
 * zipcode with no punctuation.
 *
 * Usage:
 * <input type="text" name="zipcode" data-ng-model="myModel.zipcode" data-zipcode-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const zipcodeLengthValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^\d{5}$/;

            ctrl.$validators.zipcodeLength = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("zipcodeLengthValidator", zipcodeLengthValidator);

/***** Zipcode Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for a five-digit
 * zipcode with no punctuation.
 *
 * Usage:
 * <input type="text" name="zipcode" data-ng-model="myModel.zipcode" data-zipcode-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const zipcodeDigitsValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^[0-9]*$/;

            ctrl.$validators.zipcodeDigits = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("zipcodeDigitsValidator", zipcodeDigitsValidator);

/***** Address Pattern Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for Address.
 *
 * Usage:
 * <input type="text" name="stline1" data-ng-model="myModel.stline1" data-address-pattern-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const addressPatternValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^(?=.*\d)[a-zA-Z0-9\-/#&; ]+$/;

            ctrl.$validators.addressPattern = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("addressPatternValidator", addressPatternValidator);

/***** Zipcode Pattern Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for a five-digit
 * zipcode with no punctuation.
 *
 * Usage:
 * <input type="text" name="zipcode" data-ng-model="myModel.zipcode" data-zipcode-pattern-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const zipcodePatternValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{5}-$)/;
            const stubPattern = /^\d{5}-$/;

            ctrl.$validators.zipcodePattern = function (value) {
                if (value === "") {
                    return true;
                }

                //special case for #####-  w/o trailing hyphen.
                if (stubPattern.test(value)) {
                    elem.val(value.substring(0, 5));
                }

                const rc = pattern.test(value);

                return rc;
            };
        }
    };
};
validationDirectives.directive("zipcodePatternValidator", zipcodePatternValidator);

/***** Zipcode Alpha Numeric Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for a five-digit
 * zipcode with no punctuation.
 *
 * Usage:
 * <input type="text" name="zipcode" data-ng-model="myModel.zipcode" data-zipcode-alpha-numeric-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const zipcodeAlphaNumericValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^[-a-zA-Z0-9 ]*$/;

            ctrl.$validators.zipcodeAlphaNumeric = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("zipcodeAlphaNumericValidator", zipcodeAlphaNumericValidator);

/**
 * Extends the AngularJS $validators to validate and format an input field for ZIP codes.
 *
 * Usage:
 * <input type="text" name="zipcode" data-ng-model="myModel.zipcode" data-zipcode-formatter-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const zipcodeFormatterValidator = function () {
    return {
        // Restrict to an attribute type.
        restrict: "A",

        // Element must have ng-model attribute.
        require: "ngModel",

        // Scope = the parent scope
        // Elem = the element the directive is on
        // Attr = a dictionary of attributes on the element
        // Ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const ZIP_CODE_PATTERN = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

            // Format the ZIP code
            function formatZipCode(value) {
                if (!value) return value;

                // Allow only numeric characters and hyphens
                value = value.replace(/[^0-9-]/g, "");

                // Add a hyphen after the first 5 digits if more digits are entered
                if (value.length > 5 && value[5] !== "-") {
                    value = value.slice(0, 5) + "-" + value.slice(5);
                }

                // Restrict to a maximum of 10 characters (5 digits + 1 hyphen + 4 digits)
                if (value.length > 10) {
                    value = value.slice(0, 10);
                }

                return value;
            }

            // Validator to check if the ZIP code matches the required pattern
            ctrl.$validators.zipcodeFormatter = function (value) {
                return value === "" || ZIP_CODE_PATTERN.test(value);
            };

            // Parser to format the model value
            ctrl.$parsers.push(function (value) {
                const formattedValue = formatZipCode(value);
                ctrl.$setViewValue(formattedValue);
                ctrl.$render();
                return formattedValue;
            });

            // Formatter to format the view value
            ctrl.$formatters.push(function (value) {
                return formatZipCode(value);
            });

            // Listen for blur events to apply formatting
            elem.on("blur", function () {
                const formattedValue = formatZipCode(elem.val());
                elem.val(formattedValue);
            });
        }
    };
};
validationDirectives.directive("zipcodeFormatterValidator", zipcodeFormatterValidator);

/**************** Directives for Account Setup - Email, Phone Number, Username, Password, Confirm/ Re-enter Password ************************/

/***** Email Validator ***********/

/**
 * Extends the AngularJS $validators to validate email address.
 *
 * Usage:
 * <input type="email" name="email" data-ng-model="myModel.email" data-email-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const emailValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@([a-zA-Z0-9\-'`]+[.])+[a-zA-Z]{2,}$/;

            ctrl.$validators.emailFormat = function (value) {
                return value === "" || value === null || value.length === 0
                    ? true
                    : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("emailValidator", emailValidator);

/***** Phone Number Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for a ten-digit
 * Phone Number with no punctuation.
 *
 * Usage:
 * <input type="text" name="phoneNumber" data-ng-model="myModel.phoneNumber" data-phone-number-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */

const phoneNumberValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^\d{10}$/;

            ctrl.$validators.phoneNumberDigits = function (value) {
                if (value !== "" && value != "(___) ___-____") return pattern.test(value);
                else {
                    return true;
                }
            };
        }
    };
};
validationDirectives.directive("phoneNumberValidator", phoneNumberValidator);

/***** Phone Number Numeric Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for phone number
 * to be a numeric only entry.
 *
 * Usage:
 * <input type="tel" name="phoneNumber" data-ng-model="myModel.phoneNumber" data-phone-number-numeric-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const phoneNumberNumericValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^[0-9]*$/;

            ctrl.$validators.phoneNumberNumeric = function (value) {
                return value === "" && value === "(___) ___-____" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("phoneNumberNumericValidator", phoneNumberNumericValidator);

const phoneNumberOnlyValidator = function () {
    return {
        require: "ngModel",
        link: function (scope, element, attr, ngModelCtrl) {
            function fromUser(text) {
                if (text != null && text != "") {
                    const transformedInput = text.replace(/[^0-9]/g, "");
                    if (transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                } else {
                    return true;
                }
            }
            ngModelCtrl.$parsers.push(fromUser);
        }
    };
};
validationDirectives.directive("phoneNumberOnly", phoneNumberOnlyValidator);

/***** Username Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for a username that must be at least
 * 6 characters long and may not be more than 63 characters. It must also be alphanumeric and have
 * at least one number and one letter.
 *
 * Usage:
 * <input type="text" name="username" data-ng-model="myModel.username" data-username-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const usernameValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const noSpacePattern = /^\S*$/;
            const minLengthPattern = /^[\s\S]{6,}$/;

            const usernameNumberPattern = /\d{1}/;
            const letterPattern = /(.*[A-Za-z]){3}/i;
            const allowedSpecialCharactersPattern = /^[@_.A-Za-z0-9-]+$/;

            ctrl.$validators.usernameNoSpace = function (value) {
                return noSpacePattern.test(value);
            };

            ctrl.$validators.minLength = function (value) {
                return minLengthPattern.test(value);
            };

            ctrl.$validators.usernameNumberPattern = function (value) {
                return usernameNumberPattern.test(value);
            };

            ctrl.$validators.letterPattern = function (value) {
                return letterPattern.test(value);
            };

            ctrl.$validators.allowedSpecialCharactersPattern = function (value) {
                if (value.length > 0) {
                    return allowedSpecialCharactersPattern.test(value);
                } else {
                    return true;
                }
            };

            /*
             ******************************************
              commented out for DEFC-17319
            *******************************************
            //
            // Set 'empty' class on the 'form-group' container when the field is cleared
            elem.on('keyup', function(e) {
                if (e.which !== 9 && e.which !== 0) { // only do when NOT enter or tab key
                    if ( ctrl.$viewValue === '' ) {
                        elem.parent().addClass('empty');
                    }
                    else {
                        elem.parent().removeClass('empty');
                    }
                }
            });
            */
        }
    };
};
validationDirectives.directive("usernameValidator", usernameValidator);

/***** Password Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for a password that must be
 * At least 8 characters long
 * And include at least 3 from the 4 set of rules below:
 * 1) At least one number
 * 2) At least one lowercase letter
 * 3) At least one uppercase letter
 * 4) At least one special character *
 *
 * Usage:
 * <input type="text" name="password" data-ng-model="myModel.password" data-password-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const passwordValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const minLengthPattern = /^[\s\S]{8,}$/;
            const maxLengthPattern = /^[\s\S]{0,63}$/;
            const numberPattern = /\d{1}/;
            const lowercasePattern = /^(?=.*[a-z]){1}/;
            const uppercasePattern = /^(?=.*[A-Z]){1}/;
            const specialCharPattern = /^(?=.*[=$@$!#%*?&+#._-]){1}/;

            ctrl.$validators.minLength = function (value) {
                return minLengthPattern.test(value);
            };

            ctrl.$validators.maxLength = function (value) {
                return maxLengthPattern.test(value);
            };

            ctrl.$validators.numberPattern = function (value) {
                const count = validationCount(value);
                if (count >= 3) {
                    return true;
                } else {
                    return numberPattern.test(value);
                }
            };

            ctrl.$validators.lowercasePattern = function (value) {
                const count = validationCount(value);
                if (count >= 3) {
                    return true;
                } else {
                    return lowercasePattern.test(value);
                }
            };

            ctrl.$validators.uppercasePattern = function (value) {
                const count = validationCount(value);
                if (count >= 3) {
                    return true;
                } else {
                    return uppercasePattern.test(value);
                }
            };

            ctrl.$validators.specialCharPattern = function (value) {
                const count = validationCount(value);
                if (count >= 3) {
                    return true;
                } else {
                    return specialCharPattern.test(value);
                }
            };

            ctrl.$validators.meetCondition0 = function (value) {
                const count = validationCount(value);
                if (count === 0) {
                    return false;
                } else {
                    return true;
                }
            };

            ctrl.$validators.meetCondition1 = function (value) {
                const count = validationCount(value);
                if (count === 1) {
                    return false;
                } else {
                    return true;
                }
            };

            ctrl.$validators.meetCondition2 = function (value) {
                const count = validationCount(value);
                if (count === 2) {
                    return false;
                } else {
                    return true;
                }
            };

            //
            // Set 'empty' class on the 'form-group' container when the field is cleared
            elem.on("keyup", function (e) {
                if (e.which !== 9 && e.which !== 0) {
                    if (ctrl.$viewValue === "") {
                        elem.parent().addClass("empty");
                    } else {
                        elem.parent().removeClass("empty");
                    }
                }
            });
        }
    };
};

const validationCount = function (value) {
    const numberPattern = /\d{1}/;
    const lowercasePattern = /^(?=.*[a-z]){1}/;
    const uppercasePattern = /^(?=.*[A-Z]){1}/;
    const specialCharPattern = /^(?=.*[=$@$!#%*?&+#._-]){1}/;

    let counter = 0;
    if (lowercasePattern.test(value)) {
        counter++;
    }
    if (uppercasePattern.test(value)) {
        counter++;
    }
    if (numberPattern.test(value)) {
        counter++;
    }
    if (specialCharPattern.test(value)) {
        counter++;
    }

    return counter;
};

validationDirectives.directive("passwordValidator", passwordValidator);

/***** Confirm/ Re-enter Password Validator***********/

/**
 * Confirm a user's password
 */
const compareTo = function () {
    return {
        // element must have ng-model attribute.
        require: "ngModel",

        scope: {
            otherModelValue: "=compareTo"
        },
        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        link: function (scope, element, attr, ngModel) {
            ngModel.$validators.compareTo = function (modelValue) {
                return modelValue == scope.otherModelValue;
            };

            scope.$watch("otherModelValue", function () {
                ngModel.$validate();
            });
        }
    };
};
validationDirectives.directive("compareTo", compareTo);

/***** Account Recovery screen ***********/

/**
 * Compare if Password matches username
 */
const compareToUsername = function () {
    return {
        // element must have ng-model attribute.
        require: "ngModel",

        scope: {
            otherModelValue: "=compareToUsername"
        },
        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        link: function (scope, element, attr, ngModel) {
            ngModel.$validators.compareToUsername = function (modelValue) {
                const modelValueUpperCase = modelValue ? modelValue.toUpperCase() : "";
                const otherModelValueUpperCase = scope.otherModelValue
                    ? scope.otherModelValue.toUpperCase()
                    : "";
                return modelValueUpperCase != otherModelValueUpperCase;
            };

            scope.$watch("otherModelValue", function (newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    ngModel.$validate();
                }
            });
        }
    };
};
validationDirectives.directive("compareToUsername", compareToUsername);

/**
 * Compare if the model value matches the targetValue and return false if the values are equal
 */
const cannotMatch = function () {
    return {
        // element must have ng-model attribute.
        require: "ngModel",

        scope: {
            targetValue: "=cannotMatch"
        },
        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        link: function (scope, element, attr, ngModel) {
            ngModel.$validators.cannotMatch = function (modelValue) {
                return modelValue.toUpperCase() !== scope.targetValue.toUpperCase();
            };

            scope.$watch("targetValue", function (newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    ngModel.$validate();
                }
            });
        }
    };
};
validationDirectives.directive("cannotMatch", cannotMatch);

/**************** Directives for Registration With  Pin************************/

/***** PIN Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for a 4-digit
 * PIN Number with no punctuation.
 *
 * Usage:
 * <input type="text" name="pin" data-ng-model="myModel.pin" data-pin-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const pinValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^[0-9]*$/;
            ctrl.$validators.pinDigits = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("pinValidator", pinValidator);

/***** PIN Length Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for a four-digit
 * PIN with no punctuation.
 *
 * Usage:
 * <input type="text" name="pin" data-ng-model="individual.pin" data-pin-length-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const pinLengthValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^[\S]{4,64}$/;

            ctrl.$validators.pinLength = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("pinLengthValidator", pinLengthValidator);

/***** First Name Length Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for First Name

 *
 * Usage:
 * <input type="text" name="firstName" data-ng-model="ipersonalInfo.firstName" data-first-name-length-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const firstNameLengthValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^[\S]{1,20}$/;

            ctrl.$validators.firstNameLength = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("firstNameLengthValidator", firstNameLengthValidator);

/***** Name Pattern Validator***********/

const namePatternValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^[a-zA-Z' ]+$/;

            ctrl.$validators.namePattern = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("namePatternValidator", namePatternValidator);

/***** Last Name Length Validator***********/

const lastNameLengthValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^[\S]{1,35}$/;

            ctrl.$validators.lastNameLength = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("lastNameLengthValidator", lastNameLengthValidator);

/**************** Directives for Validation of Activation Code************************/

/***** Activation Code Validator***********/

/**
 * Extends the AngularJS $validators to validate an input field for a 8-digit
 * Activation Code with no punctuation.
 *
 * Usage:
 * <input type="text" name="verificationCode" data-ng-model="myModel.verificationCode" data-pin-validator />
 *
 * Note that we add the data- prefix to ensure valid HTML.
 *
 * @returns {{restrict: string, require: string, link: Function}}
 */
const activationcodeValidator = function () {
    return {
        // restrict to an attribute type.
        restrict: "A",

        // element must have ng-model attribute.
        require: "ngModel",

        // scope = the parent scope
        // elem = the element the directive is on
        // attr = a dictionary of attributes on the element
        // ctrl = the controller for ngModel.
        link: function (scope, elem, attr, ctrl) {
            const pattern = /^\d{8}$/;

            ctrl.$validators.activationcodeEightDigits = function (value) {
                return value === "" ? true : pattern.test(value);
            };
        }
    };
};
validationDirectives.directive("activationcodeValidator", activationcodeValidator);

export default validationDirectives;
