import React, { MouseEvent, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslations } from "../../../queries";
import LoginHelpEvents from "./LoginHelpEvents";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import eventBus from "../../../../utils/setEventBus";
import { LOGIN_HELP_NO_PASSWORD, LOGIN_HELP_WITH_ACCU } from "../../../routes";
import { getAccuCode } from "../../../services/accuCodeService";

interface LoginHelpOptionsProps {
    accu: string;
    location?: { path: (path: string) => void };
    scope?: { $apply: () => void; $root: { featureName: string } };
}

interface LoginHelpOptionsTranslations {
    button: {
        cancel: string;
        continue: string;
    };
    loginHelpFailure: {
        accountNotFoundDesc: string;
        accountNotFoundHdg: string;
        anotherWay: string;
        emailNotFoundDesc: string;
        emailNotFoundHdg: string;
        enterMoreInfo: string;
        letsTry: string;
        tryAgainBtn: string;
        tryAnotherEmail: string;
    };
}
const LoginHelpLetsTryElse = ({ accu, location, scope }: LoginHelpOptionsProps) => {
    const { loginHelpFailure, button } = useTranslations<LoginHelpOptionsTranslations>();
    const [updatedFlowType, setUpdatedFlowType] = React.useState("freemium");
    const navigate = useNavigate();
    const getAccu = getAccuCode();
    const currentAccu = accu ? accu : getAccu ? getAccu : "Empower";
    const { state } = useLocation();
    const flowType = String(state?.type).toLowerCase();

    const mainHeading =
        flowType === "freemium"
            ? loginHelpFailure.emailNotFoundHdg
            : loginHelpFailure.accountNotFoundHdg;
    const subHeading =
        flowType === "freemium"
            ? loginHelpFailure.emailNotFoundDesc
            : loginHelpFailure.accountNotFoundDesc;
    const continueBtnDesc =
        flowType === "freemium" ? loginHelpFailure.enterMoreInfo : button.continue;

    useEffect(() => {
        if (state !== null) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setUpdatedFlowType(flowType);
        }
    }, [state, flowType]);

    const tryAgainBtn =
        updatedFlowType === "freemium"
            ? loginHelpFailure.tryAnotherEmail
            : loginHelpFailure.tryAgainBtn;
    const tryAgainBtnId =
        updatedFlowType === "freemium"
            ? "try_another_email"
            : String(tryAgainBtn).trim().toLowerCase().replace(/\s/g, "_");
    const buttonRef = React.useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    }, []);

    const updateAngularRoutingState = useCallback(
        (destination) => {
            /**
             * TODO: Delete this function when Angular is removed. Since we are using the React router
             * we need to update the Angular routing state to match the new React routing state.
             */
            if (location && scope) {
                location.path(destination);
                scope.$apply();
            }
        },
        [location, scope]
    );

    /**
     * Affiliate failure
     *    Continue -> LoginHelpNoPassword
     * Freemium failure
     *    Continue -> LoginHelpWithAccu
     * @param event
     */
    const handleContinue = (event: MouseEvent) => {
        event.preventDefault();
        const payload = String(event.currentTarget.id).toLowerCase();
        eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_BUTTON, event.target, payload);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: payload,
            payload: {
                payload
            }
        });

        const dest =
            updatedFlowType === "freemium"
                ? LOGIN_HELP_WITH_ACCU + currentAccu
                : LOGIN_HELP_NO_PASSWORD;

        navigate("/" + dest, {
            replace: true,
            state: {
                type: updatedFlowType,
                failedAffiliate: state?.failedAffiliate,
                failedFreemium: state?.failedFreemium
            }
        });

        updateAngularRoutingState(dest);
    };

    /**
     * Affiliate failure
     *   Try Again -> LoginHelp with accu
     * Freemium failure
     *   Try Again -> LoginHelpNoPassword
     * @param event
     */
    const handleTryAgain = (event: MouseEvent) => {
        event.preventDefault();
        const payload = String(event.currentTarget.id).toLowerCase();
        eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_BUTTON, event.target, payload);

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: tryAgainBtnId,
            payload: {
                payload
            }
        });

        const dest =
            updatedFlowType === "freemium"
                ? LOGIN_HELP_NO_PASSWORD
                : LOGIN_HELP_WITH_ACCU + currentAccu;

        navigate("/" + dest, {
            replace: true,
            state: {
                type: updatedFlowType,
                failedAffiliate: state?.failedAffiliate,
                failedFreemium: state?.failedFreemium
            }
        });
        updateAngularRoutingState(dest);
    };

    return (
        <div className="loginhelp-container" data-testid="login-help-lets-try-else">
            <h1>{mainHeading}</h1>
            <div className="description mb-50">{subHeading}</div>
            <div className="button-container">
                <button
                    id={button.continue}
                    className="btn btn-primary mb-25"
                    type="submit"
                    aria-label={button.continue}
                    data-selection={LoginHelpEvents.CTA_BUTTON}
                    onClick={handleContinue}
                    ref={buttonRef}
                >
                    {continueBtnDesc}
                </button>
                <button
                    id={tryAgainBtnId}
                    className="btn"
                    type="submit"
                    aria-label={tryAgainBtn}
                    data-selection={LoginHelpEvents.CTA_BUTTON}
                    onClick={handleTryAgain}
                >
                    {tryAgainBtn}
                </button>
            </div>
        </div>
    );
};

export default LoginHelpLetsTryElse;
