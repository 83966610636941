import ReferenceData from "../../constants/ReferenceData";
import { executeGetWithPcapAuth } from "../../utils/appUtils";
import localBuildConfig from "../../../../../../build/buildConfig.json";

export const multiAuth = localBuildConfig.multiAuth;
export const currentPcBaseApiUrl = multiAuth[window.subdomain] ?? multiAuth["Default"];
const getGoHome = async (token: string): Promise<string> => {
    const liveUrl = `${currentPcBaseApiUrl}${ReferenceData.GET_GO_HOME}?token=${token}`;
    const result = await executeGetWithPcapAuth(liveUrl, "getGoHomeFault");
    return result.data;
};

export default getGoHome;
