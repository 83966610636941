import React, { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useRetrieveUserNameForOneId, useTranslations } from "../../../queries";
import { LinkingConfirmationTranslations } from "../types";
import { QUERY_KEYS } from "../../../queries/constants";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { getNextDestination } from "../../../services/oneId/linkablePlansService";
import stateMap from "../../../services/redirect/StateMap";
import eventBus from "../../../../utils/setEventBus";
import OneIdEvents from "../events/OneIdEvents";
import { useNavigate } from "react-router-dom";
import useRedirect from "../../../hooks/useRedirect";

const LinkedConfirmationComponent = () => {
    const { data, isError, isLoading } = useRetrieveUserNameForOneId();
    const translations = useTranslations<LinkingConfirmationTranslations>("linkingConfirmation");

    const { data: destinationData, isLoading: destinationDataLoading } = useQuery({
        queryKey: [QUERY_KEYS.GET_DESTINATION],
        queryFn: getNextDestination
    });

    const navigate = useNavigate();
    const redirect = useRedirect();

    const continueClick = useCallback(() => {
        const url = destinationData?.destinationUrl;
        const primaryPlanUrl = stateMap.get("ONEID_PRIMARY_PLAN_STATE") || "";

        eventBus.dispatch(OneIdEvents.CONFIRMATION_CONTD_CLICK, this);
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: OneIdEvents.CONFIRMATION_CONTD_CLICK
            }
        });

        if (url !== "participant/#/choose-primaryplan") {
            redirect(destinationData);
        } else {
            navigate(primaryPlanUrl, { state: { oneIdFlow: "linking" }, replace: true });
        }
    }, [destinationData, navigate, redirect]);

    if (isError) {
        return <div>{translations?.errorRetrievingUserName}</div>;
    }

    return (
        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 account-selection ">
            {(destinationDataLoading || isLoading) && (
                <div id="statementsDocs-spinner" className="loaderBackground just-element">
                    <div className="loader"></div>
                </div>
            )}
            {translations && (
                <div className="linkedConfirm inner-container with-padding with-shadow clearfix">
                    <h1>{translations.nextSteps}</h1>
                    <div className="message-container">
                        <div className="icon-text-container">
                            {translations.checkIcon && (
                                <img src={translations.checkIcon} alt="Check Icon" />
                            )}
                            <p className="bold">{translations.oneIdTitle}</p>
                        </div>
                        <p className="bold">{data?.userName}</p>
                        <p>{translations.oneIdDescription}</p>
                    </div>
                    <div className="message-container">
                        <div className="icon-text-container">
                            {translations.checkIcon && (
                                <img src={translations.checkIcon} alt="Check Icon" />
                            )}
                            <p className="bold">{translations.oneIdTitle1}</p>
                        </div>
                        <p>{translations.oneIdDescription2}</p>
                    </div>
                    <div className="button-container">
                        <button className="btn btn-primary btn-lg" onClick={continueClick}>
                            {translations.oneIdContinue}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LinkedConfirmationComponent;
