import ReferenceData from "../../constants/ReferenceData";
import { executeGet, executePost } from "../../utils/appUtils";

const handleSuccess = (res) => {
    if (res && res.data) {
        return res.data;
    } else if (res) {
        return res;
    }
};

const handleError = (err, service) => {
    console.log(`${service} Error: ${err.data}`);
    throw err;
};

export const retrieveContactDetails = (isNpdiFlow) => {
    const endpoint = isNpdiFlow
        ? ReferenceData.NPDI.RETRIEVE_CONTACT_DETAILS
        : ReferenceData.ACCOUNT_SETUP.RETRIEVE_CONTACT_DETAILS;
    const url = __authenticationUrl__ + endpoint;

    return executeGet(url)
        .then(handleSuccess)
        .catch((err) => handleError(err, "retrieveContactDetails"));
};

export const updateContact = (params) => {
    const url = __authenticationUrl__ + ReferenceData.ACCOUNT_SETUP.UPDATE_CONTACT;

    return executePost(url, "updateContact", params)
        .then(handleSuccess)
        .catch((err) => handleError(err, "updateContact"));
};

export const registerUser = (params) => {
    const handleRegisterSuccess = (res) => {
        if (res && res.data) {
            return res;
        }
    };

    const url = __authenticationUrl__ + ReferenceData.ACCOUNT_SETUP.REGISTER_USER;

    return executePost(url, "registerUser", params)
        .then(handleRegisterSuccess)
        .catch((err) => {
            if (err && err.data) {
                console.log(url + " registerUser Error:", JSON.stringify(err.data));
                return err.data;
            } else {
                handleError(err, "registerUser");
            }
        });
};
