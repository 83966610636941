import React, {
    ChangeEvent,
    ClipboardEvent,
    KeyboardEvent,
    MouseEvent,
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import { useTranslations } from "../../../../queries";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { Controller, useForm } from "react-hook-form";
import eventBus from "../../../../../utils/setEventBus";
import { useLocation, useNavigate } from "react-router-dom";
import authenticateSms from "../../../../services/redwoodMfa/authenticateSms";
import authenticatePhone from "../../../../services/redwoodMfa/authenticatePhone";
import { LOGIN_HELP_RESET_CREATE_PASSWORD } from "../../../../routes";
import { getCookie } from "../../../../services/accuCodeService";

interface VerificationCodeEntryTranslations {
    app: {
        common: {
            labels: {
                cancel: string;
                continue: string;
            };
        };
    };
    button: {
        continue: string;
    };

    forgotPasswordTitle: string;
    loginHelpResetPwd: {
        callDescription: string;
        codeLengthErrorMessage: string;
        emailCode: string;
        emailDescription: string;
        loginHelpMfaCodeLength: number;
        phoneCode: string;
        smsCode: string;
        smsDescription: string;
    };
}

interface VerificationCodeEntryFields {
    code: string[];
    rememberDevice: boolean;
}

const LoginHelpResetPwdVerification = () => {
    const { state } = useLocation();
    const selectedMfaValue = state?.selectedOptionType;
    const emailAddr = state?.emailAddr;
    const [selectedMfaOption, setSelectedMfaOption] = React.useState<string>("");
    const [isPolling, setIsPolling] = useState<boolean>(false);
    const { loginHelpResetPwd, forgotPasswordTitle, app } =
        useTranslations<VerificationCodeEntryTranslations>();
    const [error, setError] = useState<string | null>(null);
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const navigate = useNavigate();
    const interval = 3000;
    const maxAttempts = 30;
    const csrfToken = state?.csrfToken || "";
    const accu = getCookie("accu");

    const {
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid }
    } = useForm<VerificationCodeEntryFields>({
        defaultValues: {
            code: new Array(loginHelpResetPwd.loginHelpMfaCodeLength).fill(""),
            rememberDevice: false
        },
        mode: "all"
    });
    const FIELDS = Array.from({ length: loginHelpResetPwd.loginHelpMfaCodeLength }, (_, index) => ({
        key: index
    }));

    useEffect(() => {
        // focus on the first input field on component mount
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);
    useEffect(() => {
        if (selectedMfaValue !== undefined) {
            // eslint-disable-next-line react-hooks-extra/no-direct-set-state-in-use-effect
            setSelectedMfaOption(selectedMfaValue);
        }
    }, [selectedMfaValue, state]);

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const index = Number(event.currentTarget.dataset.key);
            if (/^[0-9]$/.test(event.target.value.slice(-1))) {
                if (index < loginHelpResetPwd.loginHelpMfaCodeLength - 1) {
                    // jump to the next input if valid entry and not the last input
                    inputRefs.current[index + 1].focus();
                }
                return event.target.value.slice(-1);
            }
            return "";
        },
        [loginHelpResetPwd.loginHelpMfaCodeLength]
    );
    const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            const index = Number(event.currentTarget.dataset.key);
            if (index > 0 && (event.key === "ArrowLeft" || (event.key === "Backspace" && !value))) {
                // jump to previous input if backspace is pressed and the input is empty or if the left arrow key is pressed
                event.preventDefault();
                inputRefs.current[index - 1].focus();
            }
            if (
                index < loginHelpResetPwd.loginHelpMfaCodeLength - 1 &&
                event.key === "ArrowRight"
            ) {
                // jump to the next input if the right arrow key is pressed
                inputRefs.current[index + 1].focus();
            }
        },
        [loginHelpResetPwd.loginHelpMfaCodeLength]
    );

    const handlePaste = useCallback(
        (event: ClipboardEvent<HTMLInputElement>) => {
            event.preventDefault();
            const startIndex = Number(event.currentTarget.dataset.key);
            const paste = event.clipboardData.getData("text/plain");
            paste.split("").forEach((digit, index) => {
                if (
                    startIndex + index < loginHelpResetPwd.loginHelpMfaCodeLength &&
                    /[0-9]/.test(digit)
                ) {
                    setValue(`code.${startIndex + index}`, digit);
                    if (startIndex + index < loginHelpResetPwd.loginHelpMfaCodeLength - 1) {
                        inputRefs.current[startIndex + index + 1].focus();
                    }
                }
            });
        },
        [loginHelpResetPwd.loginHelpMfaCodeLength, setValue]
    );
    const startPolling = useCallback(() => {
        let attempts = 0;
        const id = setInterval(async () => {
            try {
                const isLoginHelp = true;
                const authData = await authenticatePhone({ csrfToken, isLoginHelp });
                setIsPolling(true);
                if (authData?.spData?.pullStatus) {
                    setIsPolling(false);
                    clearInterval(id);
                    if (
                        authData.spHeader.authLevel === "SESSION_AUTHENTICATED" &&
                        authData.spHeader.status === "ACTIVE"
                    ) {
                        navigate("/" + LOGIN_HELP_RESET_CREATE_PASSWORD, { replace: true });
                    }
                } else if (authData.spHeader.errors && authData.spHeader.errors.length > 0) {
                    setError(authData.spHeader.errors[0].message);
                    setIsPolling(false);
                    clearInterval(id);
                }

                attempts += 1;
                if (attempts >= maxAttempts) {
                    clearInterval(id);
                }
            } catch (error) {
                console.error("Polling error:", error);
                clearInterval(id);
                setIsPolling(false);
            }
        }, interval);

        return () => clearInterval(id);
    }, [csrfToken, maxAttempts, navigate]);

    useEffect(() => {
        if (selectedMfaOption === "voice") {
            const stopPolling = startPolling();
            return () => stopPolling();
        }
    }, [selectedMfaOption, startPolling, isPolling]);

    const handleSmsCodeSubmit = async (formData) => {
        const { code } = formData;
        const smsCode = code.join("");
        let authData;
        const isLoginHelp = true;
        if (selectedMfaOption === "sms") {
            authData = await authenticateSms({ csrfToken, smsCode, isLoginHelp });
        } /* else if (selectedMfaOption === "email") {
            authData = await authenticateEmail({ csrfToken, smsCode, });
        } */
        if (authData.authLevel === "SESSION_AUTHENTICATED" && authData.status === "ACTIVE") {
            navigate("/" + LOGIN_HELP_RESET_CREATE_PASSWORD, {
                replace: true,
                state: { username: emailAddr, csrfToken }
            });
        } else if (authData.errors && authData.errors.length > 0) {
            setError(authData.errors[0].message);
        }
    };

    const handleInvalid = useCallback(() => {
        const verifivationCodelength = loginHelpResetPwd.loginHelpMfaCodeLength;
        control.setError("root", {
            message: StringUtil.supplant(loginHelpResetPwd.codeLengthErrorMessage, {
                verifivationCodelength
            })
        });
    }, [
        control,
        loginHelpResetPwd.codeLengthErrorMessage,
        loginHelpResetPwd.loginHelpMfaCodeLength
    ]);

    const dispatchAmplitudeEvent = useCallback(
        (event: MouseEvent<HTMLElement>, selectedBtn) => {
            const { selection } = event.currentTarget.dataset;
            const payload = event.currentTarget.dataset.payload || event.currentTarget.textContent;
            eventBus.dispatch(AMPLITUDE_EVENTS.SELECT_BUTTON, event.target, String(selection));
            dispatchAmplitude({
                eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
                selection: String(selection),
                payload: {
                    payload
                }
            });
            if (selectedBtn === "cancel") {
                window.location.href = `/participant/#/login?accu=${accu}`;
                window.location.reload();
            }
        },
        [accu]
    );
    return (
        <form
            className="mfa-container"
            data-testid="login-help-reset-pwd-verification-code"
            autoComplete="off"
            onSubmit={handleSubmit(handleSmsCodeSubmit, handleInvalid)}
        >
            {isSubmitting && <div className="loader"></div>}
            <h2>{forgotPasswordTitle}</h2>
            <div className="description">
                {selectedMfaOption === "sms" ? loginHelpResetPwd.smsDescription : null}
                {selectedMfaOption === "voice" ? loginHelpResetPwd.callDescription : null}
                {selectedMfaOption === "email" ? loginHelpResetPwd.emailDescription : null}
            </div>
            <div className="code-entry-container">
                {(selectedMfaOption === "sms" ||
                    selectedMfaOption === "email" ||
                    (selectedMfaOption === "voice" && isPolling)) && (
                    <label>
                        {selectedMfaOption === "sms" && loginHelpResetPwd.smsCode}
                        {selectedMfaOption === "email" && loginHelpResetPwd.emailCode}
                        {selectedMfaOption === "voice" && isPolling && (
                            <div>
                                <div className="loader"></div>
                                <p>{loginHelpResetPwd.phoneCode}</p>
                            </div>
                        )}
                    </label>
                )}
                <div className={selectedMfaOption !== "voice" ? "login-help-mfa-gap" : ""}>
                    {selectedMfaOption !== "voice"
                        ? FIELDS.map(({ key }) => (
                              <Controller
                                  key={key}
                                  name={`code.${key}`}
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field }) => (
                                      <input
                                          {...field}
                                          aria-label={loginHelpResetPwd.smsCode}
                                          className="digit-input digit-font"
                                          data-testid={`input-${key}`}
                                          data-key={key}
                                          onChange={(event) => field.onChange(handleChange(event))}
                                          onKeyDown={handleKeyDown}
                                          onPaste={handlePaste}
                                          ref={(el: HTMLInputElement) =>
                                              (inputRefs.current[key] = el)
                                          }
                                          inputMode="numeric"
                                          pattern="[0-9]*"
                                          min="0"
                                      />
                                  )}
                              />
                          ))
                        : null}
                </div>
                {error && <p className="error-block">{error}</p>}
            </div>
            <div className="button-container">
                <button
                    className="btn btn-primary"
                    type="submit"
                    onClick={(event) => dispatchAmplitudeEvent(event, "confirm")}
                    disabled={!isValid}
                    data-selection="continue"
                >
                    {app.common.labels.continue}
                </button>
            </div>
            <br />
            <div className="button-container">
                <button
                    className="btn"
                    data-selection="cancel"
                    onClick={(event) => dispatchAmplitudeEvent(event, "cancel")}
                >
                    {app.common.labels.cancel}
                </button>
            </div>
        </form>
    );
};

export default LoginHelpResetPwdVerification;
