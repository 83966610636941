import ReferenceData from "../../constants/ReferenceData";
import { executePost, executePostWithAuth, executeGet } from "../../utils/appUtils";
import localBuildConfig from "../../../../../../build/buildConfig.json";

export const multiAuth = localBuildConfig.multiAuth;
export const currentPcBaseApiUrl = multiAuth[window.subdomain] ?? multiAuth["Default"];

// clone AuthenticationStatusService.getAuthData
export const getAuthData = () => {
    const getAuthDataSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.GET_AUTH_DATA;
    const payload = {};

    return executePost(liveUrl, "getAuthDataFault", payload)
        .then(getAuthDataSuccess)
        .catch((err) => {
            console.log("getAuthData Error: ", err);
            throw err;
        });
};

export const callChallengeSmsAuth = (fp, pcBaseApiUrl) => {
    const callChallengeSmsAuthSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };
    const liveUrl = pcBaseApiUrl + ReferenceData.CALL_CHALLENGE_SMS_AUTH;
    const payload = fp;
    return executePostWithAuth(liveUrl, "callChallengeSmsAuthFault", payload)
        .then(callChallengeSmsAuthSuccess)
        .catch((err) => {
            console.log("callChallengeSmsAuth Error: ", err);
            throw err;
        });
};
export const callAuthenticateSmsAuth = (fp, pcBaseApiUrl) => {
    const callAuthenticateSmsAuthSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };
    const liveUrl = pcBaseApiUrl + ReferenceData.CALL_AUTHENTICATE_SMS_AUTH;
    const payload = fp;
    return executePostWithAuth(liveUrl, "callAutheniticateSmsAuthFault", payload)
        .then(callAuthenticateSmsAuthSuccess)
        .catch((err) => {
            console.log("callAuthenticateSmsAuth Error: ", err);
            throw err;
        });
};

export const callAuthenticatePart = (fp, path) => {
    const callAuthenticatePartSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = currentPcBaseApiUrl + ReferenceData.CALL_AUTHENTICATEPART;
    const payload = fp;

    return executePostWithAuth(liveUrl, "callAuthenticatePartFault", payload, path)
        .then(callAuthenticatePartSuccess)
        .catch((err) => {
            console.log("callAuthenticatePart Error: ", err);
            throw err;
        });
};

export const callAuthenticateToken = (fp) => {
    const callAuthenticateTokenPcapSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };
    const liveUrl = __baseApiUrl__ + ReferenceData.CALL_AUTHENTICATE_TOKEN;
    const payload = fp;
    return executePostWithAuth(liveUrl, "callAuthenticateTokenPcapFault", payload)
        .then(callAuthenticateTokenPcapSuccess)
        .catch((err) => {
            console.log("callAuthenticateTokenPcap Error: ", err);
            throw err;
        });
};

export const callAuthenticateLite = (fp) => {
    const callAuthenticateLiteSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.CALL_AUTHENTICATELITE;
    const payload = fp;

    return executePostWithAuth(liveUrl, "callAuthenticateLiteFault", payload)
        .then(callAuthenticateLiteSuccess)
        .catch((err) => {
            console.log("callAuthenticateLite Error: ", err);
            throw err;
        });
};

export const callAuthenticate = (fp) => {
    const callAuthenticateSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.CALL_AUTHENTICATE;
    const payload = fp;

    return executePostWithAuth(liveUrl, "callAuthenticateFault", payload)
        .then(callAuthenticateSuccess)
        .catch((err) => {
            console.log("callAuthenticate Error: ", err);
            throw err;
        });
};

export const getDefaultCustomizationRetr = () => {
    const getDefaultCustomizationRetrSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __styleEnv__ + ReferenceData.GET_DEFAULT_CUSTOMIZATION;
    const payload = {};

    return executeGet(liveUrl, "getDefaultCustomizationRetrFault", payload)
        .then(getDefaultCustomizationRetrSuccess)
        .catch((err) => {
            console.log("getDefaultCustomizationRetr Error: ", err);
            throw err;
        });
};

export const clearSpanishLanguageCookies = () => {
    const clearSpanishLanguageCookiesSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const https = "https://";
    const http = "http://";
    const period = ".";
    let newBaseApiUrl = __baseApiUrl__;
    const toLangCode = "es";
    if (
        newBaseApiUrl.indexOf(https + toLangCode) < 0 &&
        newBaseApiUrl.indexOf(http + toLangCode) < 0
    ) {
        newBaseApiUrl = newBaseApiUrl.replace(https, https + toLangCode + period);
        newBaseApiUrl = newBaseApiUrl.replace(http, http + toLangCode + period);
    }

    if (
        newBaseApiUrl.indexOf(https + toLangCode) < 0 &&
        newBaseApiUrl.indexOf(http + toLangCode) < 0
    ) {
        if (!window.location.origin) {
            window.location.origin =
                window.location.protocol +
                "//" +
                window.location.hostname +
                (window.location.port ? ":" + window.location.port : "");
        }
        newBaseApiUrl = window.location.origin;
        const domainToExclude =
            window.location.hostname.includes("localhost") ||
            window.location.hostname.includes("proj8") ||
            window.location.hostname.includes("proj10");

        if (
            newBaseApiUrl.indexOf(https + toLangCode) < 0 &&
            newBaseApiUrl.indexOf(http + toLangCode) < 0 &&
            !domainToExclude
        ) {
            newBaseApiUrl = newBaseApiUrl.replace(https, https + toLangCode + period);
            newBaseApiUrl = newBaseApiUrl.replace(http, http + toLangCode + period);
        }
    }

    const liveUrl = newBaseApiUrl + ReferenceData.CLEAR_SPANISH_LANGUAGE_COOKIES;
    const payload = {
        isArray: false
    };

    return executeGet(liveUrl, "clearSpanishLanguageCookiesFault", payload)
        .then(clearSpanishLanguageCookiesSuccess)
        .catch((err) => {
            console.log("clearSpanishLanguageCookies Error: ", err);
            throw err;
        });
};

export const clearLanguageCookies = () => {
    const clearLanguageCookiesSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.CLEAR_LANGUAGE_COOKIES;
    const payload = {
        isArray: false
    };

    return executeGet(liveUrl, "clearLanguageCookiesFault", payload)
        .then(clearLanguageCookiesSuccess)
        .catch((err) => {
            console.log("clearLanguageCookies Error: ", err);
            throw err;
        });
};

export const getPSCPath = () => {
    const getPSCPathSuccess = (results) => {
        if (results && results.data) {
            return results.data;
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.GET_PSC_PATH;
    const payload = {
        isArray: false
    };

    return executeGet(liveUrl, "getPSCPathFault", payload)
        .then(getPSCPathSuccess)
        .catch((err) => {
            console.log("getPSCPath Error: ", err);
            throw err;
        });
};

export const getEligibilityLite = () => {
    const getEligibilityLiteSuccess = (results) => {
        if (results && results.data) {
            if (results.data.data) {
                return results.data.data;
            }
            return {
                homePageEligibility: false,
                isRetail: false
            };
        }
    };

    const liveUrl = __baseApiUrl__ + ReferenceData.GET_ELIGIBILITY_LITE;

    return executeGet(liveUrl, "getEligibilityLiteFault")
        .then(getEligibilityLiteSuccess)
        .catch((err) => {
            console.log("getEligibilityLite Error: ", err);
            throw err;
        });
};
