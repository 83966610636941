import StringUtil from "core-ui/client/src/app/core/util/StringUtil";
import ReferenceData from "../../constants/ReferenceData.js";
import { executeGet, executePost, executePostWithIdProof } from "../../utils/appUtils.js";

const handleSuccess = (res) => {
    if (res && res.data) {
        return res.data;
    }
};

const handleError = (err, service) => {
    console.log(`${service} Error: ${err.data}`);
    throw err;
};

export const retrievePlansAvailableForLinkingProfile = () => {
    const url =
        __baseApiUrl__ + ReferenceData.LINKABLE_PLANS.RETRIEVE_PLANS_AVAILABLE_FOR_LINKING_PROFILE;

    return executeGet(url)
        .then(handleSuccess)
        .catch((err) => handleError(err, "retrievePlansForLinkingProfile"));
};

export const retrievePlansAvailableForLinking = () => {
    const url = __baseApiUrl__ + ReferenceData.LINKABLE_PLANS.RETRIEVE_PLANS_AVAILABLE_FOR_LINKING;

    return executeGet(url, null, null, true)
        .then(handleSuccess)
        .catch((err) => handleError(err, "retrievePlansAvailableForLinking"));
};

export const skipAndNavigate = () => {
    const url = __authenticationUrl__ + ReferenceData.LINKABLE_PLANS.SKIP_AND_NAVIGATE;

    return executeGet(url)
        .then(handleSuccess)
        .catch((err) => handleError(err, "skipAndNavigate"));
};

export const linkAccounts = (params) => {
    const handleLASuccess = (res) => {
        if (res && res.data) {
            return res.data.data;
        }
    };

    const url = __baseApiUrl__ + ReferenceData.LINKABLE_PLANS.LINK_ACCOUNTS;

    return executePostWithIdProof(url, "linkAccounts", params)
        .then(handleLASuccess)
        .catch((err) => handleError(err, "linkAccounts"));
};

export const isMfaFinishedPrior = () => {
    const url = __authenticationUrl__ + ReferenceData.LINKABLE_PLANS.IS_MFA_FINISHED_PRIOR;

    return executeGet(url)
        .then(handleSuccess)
        .catch((err) => handleError(err, "isMfaFinishedPrior"));
};

export const getContextEligibilityForHiding = (params) => {
    const url =
        __baseApiUrl__ +
        StringUtil.supplant(
            ReferenceData.LINKABLE_PLANS.GET_CONTEXT_ELIGIBILITY_FOR_HIDING,
            params
        );

    return executeGet(url, "getContextEligibilityForHiding")
        .then(handleSuccess)
        .catch((err) => handleError(err, "getContextEligibilityForHiding"));
};

export const updatePinAuthCode = (params) => {
    const url = __authenticationUrl__ + ReferenceData.LINKABLE_PLANS.UPDATE_PIN_AUTH_CODE;

    return executePost(url, "updatePinAuthCode", params)
        .then(handleSuccess)
        .catch((err) => handleError(err, "updatePinAuthCode"));
};

export const retrieveUserNameForOneid = () => {
    const url = ReferenceData.LINKABLE_PLANS.RETRIEVE_USERNAME_ONEID;

    return executeGet(url, "retrieveUserNameForOneid")
        .then(handleSuccess)
        .catch((err) => handleError(err, "retrieveUserNameForOneidFault"));
};

export const getNextDestination = () => {
    const handleNextDestSuccess = (res) => {
        if (res && res.data) {
            return res.data.data;
        }
    };

    const url = ReferenceData.LINKABLE_PLANS.GET_NEXT_DESTINATION;

    return executeGet(url, "getNextDestination")
        .then(handleNextDestSuccess)
        .catch((err) => handleError(err, "getNextDestinationFault"));
};
