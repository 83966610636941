import axios from "axios";

type SPHeader = {
    SP_HEADER_VERSION: number;
    accountsMetaData: string[];
    authLevel: string;
    betaTester: boolean;
    csrf: string;
    developer: boolean;
    deviceName: string;
    idToken: string;
    isDelegate: boolean;
    personId: number;
    qualifiedLead: boolean;
    status: string;
    success: boolean;
    userGuid: string;
    userStage: string;
    username: string;
};

type getRegisterFreemiumToken = {
    data: {
        spHeader: SPHeader;
    };
};

const getRegisterFreemiumToken = async (url: string) => {
    //const formData = new FormData();
    const response = await axios.post<getRegisterFreemiumToken["data"]>(
        url + "/api/registration/getRegisterFreemiumToken",
        "",
        {
            withCredentials: true
        }
    );
    return response;
};

export default getRegisterFreemiumToken;
