import { generatePath } from "react-router-dom";
import type { RetrieveUserNameForOneId } from "../../types";
import ReferenceData from "../../constants/ReferenceData.js";
import { executeGet } from "../../utils/appUtils.js";

const retrieveUserNameForOneIdService = async (): Promise<RetrieveUserNameForOneId> => {
    const url = await generatePath(ReferenceData.PROFILE.RETRIEVE_USERNAME_FOR_ONE_ID, {});
    try {
        const response = await executeGet(url, "retrieveUserNameForOneIdFault");
        return response.data.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export default retrieveUserNameForOneIdService;
