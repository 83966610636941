import {
    ACCOUNT_RECOVERY,
    ACCOUNT_RECOVERY_MULTIPLE_USERS,
    ACCOUNT_SETUP,
    ACTIVATION_CODE_DELIVERY_OPTIONS,
    CONTACT_UPDATE,
    IDPROOF_WITH_MAILPIN,
    IDPROOF_WITH_PIN,
    IDPROOF_WITH_QUERY,
    NPDI_ACCOUNT_EXISTS,
    NPDI_LINK_ACCOUNTS,
    REGISTER_INFO,
    NPDI_ERROR,
    REGISTRATION_SUMMARY,
    NPDI_BASE,
    REGISTER,
    LOGIN,
    LINKED_CONFIRMATION,
    LINKABLE_PLANS,
    CHOOSE_PRIMARY_PLAN,
    ECONSENT_PREF
} from "../../routes";

/**
 * Map state property returned by a service to a React route
 */
const stateMap = new Map<string, string>();

stateMap.set("ACCOUNT_RECOVERY_MFA", ACTIVATION_CODE_DELIVERY_OPTIONS);
stateMap.set("ACTIVATION_CODE_INFO", ACTIVATION_CODE_DELIVERY_OPTIONS);
stateMap.set("ACCOUNT_OPENING", ACTIVATION_CODE_DELIVERY_OPTIONS);
stateMap.set("CHANGE_PASSCODE", ""); // Update with corresponding route when client/src/app/profile/forceChangePasswordTemplate.js is converted to React
stateMap.set("CHANGE_COMM_PREF", ECONSENT_PREF); // Update with corresponding route when client/src/app/eConsent/communicationPreferencesTemplate.js is converted to React
stateMap.set("ACCOUNT_SETUP", ACCOUNT_SETUP);
stateMap.set("CONTACT_INFO", ACCOUNT_SETUP);
stateMap.set("ACCOUNT_RECOVERY", ACCOUNT_RECOVERY);
stateMap.set("ACCOUNT_RECOVERY_MULTIPLE_USERS", ACCOUNT_RECOVERY_MULTIPLE_USERS);
stateMap.set("profile", ""); // Update with corresponding route when client/src/app/profile/profileContactInfoTemplate.js is converted to React
stateMap.set("NPDI_ACCOUNT_SETUP", ACCOUNT_SETUP);
stateMap.set("npdiLinkAccounts", NPDI_LINK_ACCOUNTS);
stateMap.set("REGISTER_USER", REGISTER_INFO);
stateMap.set("UPDATE_CONTACT", CONTACT_UPDATE);
stateMap.set("STATE_ACCOUNT_EXISTS", NPDI_ACCOUNT_EXISTS);
stateMap.set("ID_PROOFING_QUERIES", IDPROOF_WITH_QUERY);
stateMap.set("MAIL_PIN", IDPROOF_WITH_MAILPIN);
stateMap.set("ID_PROOFING_PIN_STATE", IDPROOF_WITH_PIN);
stateMap.set("ID_PROOFING_OTP_STATE", ""); // Update with corresponding route when client/src/app/idProof/otpIdentityVerificationTemplate.js is converted to React
stateMap.set("IND_ID_SELECTION", ""); // Update with corresponding route when client/src/app/indIdSelection/indIdSelectionTemplate.js is converted to React
stateMap.set("ID_PROOFING_OTP_VERIFY_CODE", ""); // Update with corresponding route when client/src/app/idProof/otpVerificationCodeEntryTemplate.js is converted to React
stateMap.set("ONEID_CONFIRMATION_STATE", LINKED_CONFIRMATION);
stateMap.set("ONEID_PRIMARY_PLAN_STATE", CHOOSE_PRIMARY_PLAN);
stateMap.set("ONEID_LINKING_PAGE_STATE", LINKABLE_PLANS);
stateMap.set("registrationSummary", REGISTRATION_SUMMARY);
stateMap.set("NPDI_ERROR", NPDI_ERROR);
stateMap.set("npdi", NPDI_BASE);
stateMap.set("register", REGISTER);
stateMap.set("login", LOGIN);

export default stateMap;
