export const DEFAULT_CUSTOMIZATIONS = {
    menuItemModalManifest: [
        {
            urlPattern: "#/fe-splash",
            reactView: "advisoryServicesDisclaimer",
            template: "<advisory-services-disclaimer></advisory-services-disclaimer>",
            showModalOnClick: true,
            aag: true
        },
        {
            urlPattern: "#/ibbotson-splash",
            reactView: "advisoryServicesIbbotson",
            template: "<advisory-services-ibbotson></advisory-services-ibbotson>",
            showModalOnClick: false,
            aag: true
        },
        {
            urlPattern: "/wrWithdrawalRequest",
            reactView: "hardshipWithdrawalRequest",
            template: "<hardship-withdrawal-disclaimer></hardship-withdrawal-disclaimer>",
            showModalOnClick: true,
            aag: false
        }
    ],
    faviconIco: "customizations/Default/images/magic-login/favicon.ico",
    faviconPng: "customizations/Default/images/magic-login/favicon.png",
    mobileIcons: {
        default: "customizations/Default/images/magic-login/mobile-icons/icon-60@2x.png",
        icon58x58: "customizations/Default/images/magic-login/mobile-icons/icon-Small@2x.png",
        icon76x76: "customizations/Default/images/magic-login/mobile-icons/icon-76.png",
        icon152x152: "customizations/Default/images/magic-login/mobile-icons/icon-76@2x.png",
        icon167x167: "customizations/Default/images/magic-login/mobile-icons/icon_835@2x.png",
        icon180x180: "customizations/Default/images/magic-login/mobile-icons/icon-60@3x.png",
        icon128x128:
            "customizations/Default/images/magic-login/mobile-icons/icon-android-normal.png",
        icon192x192: "customizations/Default/images/magic-login/mobile-icons/icon-android-hires.png"
    },
    metaTags: {
        description: ""
    },
    modalSrc: "customizations/Default/articles/modal.html",
    contactModalSrc: "customizations/Default/articles/contactModal.html",
    feedbackModalSrc: "customizations/Default/articles/feedbackModal.html",
    languageModalSrc: "customizations/Default/articles/languageModal.html",
    preLoginHeaderTemplate: "customizations/Default/articles/templatePreLoginHeader.html",
    preLoginBodyTemplate: "customizations/Default/articles/templatePreLoginBody.html",
    mobileLanding: {
        heading: "The power to help</br>plan your future",
        bulletedList: [
            "Check your balance and follow progress toward your financial goals",
            "Discover opportunities for your financial future and easily make changes",
            "Receive access to planning tools, simple solutions and actionable information"
        ],
        download: "Download our free app",
        metlifeHeading:
            "Log in with your laptop or desktop device for a full view of your MetLife profile.",
        appAppleLink: "https://apps.apple.com/us/app/empower-retirement/id1001257338",
        appAndroidLink: "https://play.google.com/store/apps/details?id=com.participantmobileapp"
    },
    preLoginLogoAltAttribute: "site logo",
    preLoginLogoTagline: "Your Retirement Plan",
    preLoginBanner: {
        imageSrc: "customizations/Default/images/magic-login/banner-mobile-app-xv1.jpg",
        imageSrcset: "customizations/Default/images/magic-login/banner-mobile-app-xv1@2x.jpg 2x",
        imageAlt: "",
        headline: "Access your account anytime, anywhere with the Empower mobile app.",
        subtext: "Learn more",
        href: "https://www.empower.com/learning_center/stay-on-track.shtml#/",
        target: "_blank",
        buttonText: "Learn how to register",
        buttonHref:
            "https://www.empower.com/learning_center/stay-on-track.shtml?_gl=1*o8v4fp*_ga*MzAyNTAwMDMxLjE3MjkxOTI2MDc.*_ga_MDRRLSW4FM*MTczMTQ0Mjc2OS4yMi4wLjE3MzE0NDI3NzIuNTcuMC4w#/"
    },
    preLoginArticleOne: {
        imageSrc: "customizations/Default/images/magic-login/tile-the-currency.jpg",
        imageSrcset: "customizations/Default/images/magic-login/tile-the-currency@2x.jpg 2x",
        imageAlt: "",
        headline: "Power up with the latest money news",
        subtext: "Get insights sent to your inbox free every week.",
        href: "https://www.empower.com/the-currency/newsletter",
        target: "_blank"
    },
    preLoginArticleTwo: {
        imageSrc: "customizations/Default/images/magic-login/tile-security-guarantee-v1.png",
        imageSrcset: "customizations/Default/images/magic-login/tile-security-guarantee-v1@2x.png",
        imageAlt: "",
        headline: "Empower Security Center",
        subtext: "Protecting your account and your information is our priority.",
        href: "#/articles/securityCenter",
        target: "_self"
    },
    preLoginArticleThree: {
        imageSrc: "customizations/Default/images/magic-login/tile-consolidate-twirl.jpg",
        imageSrcset: "customizations/Default/images/magic-login/tile-consolidate-twirl@2x.jpg 2x",
        imageAlt: "",
        headline: "Consolidate your retirement accounts",
        subtext: "Combine your funds and realize the simplicity of one account.",
        href: "#/articles/consolidatingYourRetirementAssets",
        target: "_self"
    },
    primaryDatabase: {
        preLoginPrimaryDB: "1"
    },
    educationCntrURL: "https://www.empower.com/learning_center/#/",
    headerNavLinks: [
        {
            text: "About your plan",
            subnavLinks: [
                {
                    href: "#/articles/aboutYourPlan",
                    text: "About your plan",
                    target: "_self"
                },
                {
                    href: "#/articles/planHighlights",
                    text: "Plan highlights",
                    target: "_self"
                },
                {
                    href: "#/articles/fees",
                    text: "Fees",
                    target: "_self"
                },
                {
                    href: "#/articles/keyActions",
                    text: "Key actions",
                    target: "_self"
                },
                {
                    href: "#/articles/enrollNow",
                    text: "Enroll now",
                    target: "_self"
                }
            ]
        },
        {
            text: "Investing",
            subnavLinks: [
                {
                    href: "#/articles/managedAccounts",
                    text: "Investment assistance",
                    tertiaryLinks: [
                        {
                            href: "#/articles/managedAccounts",
                            text: "My Total Retirement™",
                            target: "_self"
                        },
                        {
                            href: "#/articles/onlineAdvice",
                            text: "Online Advice",
                            target: "_self"
                        },
                        {
                            href: "#/articles/buildYourOwnPortfolio",
                            text: "Build your own portfolio",
                            target: "_self"
                        },
                        {
                            href: "#/articles/selfdirectedBrokerageAccount",
                            text: "Self-directed brokerage account",
                            target: "_self"
                        }
                    ]
                },
                {
                    href: "#/articles/investmentInformation",
                    text: "Investment information",
                    tertiaryLinks: [
                        {
                            href: "#/articles/investmentInformation",
                            text: "Investment information",
                            target: "_self"
                        },
                        {
                            href: "#/articles/investmentFundamentals",
                            text: "Investment fundamentals",
                            target: "_self"
                        },
                        {
                            href: "#/articles/assetClasses",
                            text: "Asset classes",
                            target: "_self"
                        },
                        {
                            href: "#/articles/assetAllocationFunds",
                            text: "Asset allocation funds",
                            target: "_self"
                        },
                        {
                            href: "#/articles/activePassiveInvestmentManagement",
                            text: "Active vs. passive investment management",
                            target: "_self"
                        },
                        {
                            href: "#/articles/guaranteedRetirementIncome",
                            text: "Guaranteed retirement income",
                            target: "_self"
                        }
                    ]
                }
            ]
        },
        {
            text: "Learning center",
            subnavLinks: [
                {
                    href: "#/articles/wellnessAndFinancialResourceCenter",
                    text: "Financial wellness",
                    target: "_self"
                },
                {
                    href: "#/articles/eNewsletter",
                    text: "eNewsletter",
                    target: "_self"
                },
                {
                    href: "#/articles/eLearningSeminars",
                    text: "eLearning seminars",
                    target: "_self"
                }
            ]
        },
        {
            text: "Plan resources",
            href: "#/articles/planResources",
            target: "_self"
        },
        {
            text: "Employer",
            href: "#/articles/employer",
            target: "_self"
        }
    ],
    securityTips: {
        esUrl: "https://docs.empower.com/EE/Empower/DOCS/Security-Tips-ES-v2.pdf",
        tertiaryLinks: [
            {
                href: "#/articles/tipsProtectYourAccounts",
                text: "Protect your retirement account(s)",
                target: "_self"
            },
            {
                href: "#/articles/tipsPersonalDevices",
                text: "Keep your personal devices safe",
                target: "_self"
            },
            {
                href: "#/articles/tipsCommunicateSafely",
                text: "Communicate safely",
                target: "_self"
            },
            {
                href: "#/articles/tipsProtectYourIdentity",
                text: "Protect your identity",
                target: "_self"
            },
            {
                href: "#/articles/tipsCommonSecurityThreats",
                text: "Be aware of common security threats",
                target: "_self"
            },
            {
                href: "#/articles/tipsUnusualActivity",
                text: "Take action if you suspect unusual activity",
                target: "_self"
            }
        ]
    },
    header: {
        languagePreference: {
            linkSpanish: "Español",
            linkEnglish: "English",
            linkSpanishLabel: "Seleccionar idioma - Inglés",
            linkEnglishLabel: "Set language - Spanish",
            languageList: [
                {
                    code: "ENG",
                    label: "English"
                },
                {
                    code: "SPN",
                    label: "Español"
                }
            ]
        }
    },
    footer: {
        beginTime: "8:00 AM",
        endTime: "10:00 PM",
        timeZone: "ET",
        defaultTimeZone: "MST",
        phoneNumber: "",
        emailAddress: "participantservices@empower.com",
        socialMedia: [
            {
                href: "https://www.facebook.com/officialempowertoday",
                imageAlt: "Visit our Facebook page",
                icon: "fa-facebook",
                text: "footer.socialMedia.facebook"
            },
            {
                href: "https://x.com/empowertoday",
                imageAlt: "Visit our X (formerly Twitter) profile",
                icon: "fa-x-twitter",
                text: "footer.socialMedia.twitter"
            },
            {
                href: "https://www.instagram.com/officialempowertoday/",
                imageAlt: "Visit our Instagram page",
                icon: "fa-instagram",
                text: "footer.socialMedia.instagram"
            },
            {
                href: "https://www.snapchat.com/add/empowertoday",
                imageAlt: "Visit our Snapchat profile",
                icon: "fa-snapchat",
                text: "footer.socialMedia.snapchat"
            },
            {
                href: "https://www.youtube.com/channel/UCFPLlGp16vPjBb-G7SnUWhQ",
                imageAlt: "Visit our YouTube channel",
                icon: "fa-youtube",
                text: "footer.socialMedia.youtube"
            },
            {
                href: "https://www.linkedin.com/company/empowertoday",
                imageAlt: "Visit our Linkedin profile",
                icon: "fa-linkedin",
                text: "footer.socialMedia.linkedin"
            },
            {
                href: "https://www.tiktok.com/@empowertoday",
                imageAlt: "Visit our Tiktok profile",
                icon: "fa-tiktok",
                text: "footer.socialMedia.tiktok"
            }
        ],
        languagePreference: {
            linkSpanish: "Español",
            linkEnglish: "English",
            languageList: [
                {
                    code: "ENG",
                    label: "English"
                },
                {
                    code: "SPN",
                    label: "Español"
                }
            ]
        },
        disclosures: [
            {
                class: "text-notation-base login-ui-hide",
                text: "footer.disclosures.considerOptions"
            },
            {
                class: "text-notation login-ui-hide",
                text: "footer.disclosures.lis"
            },
            {
                class: "text-notation",
                text: "footer.disclosures.brokerDealer"
            },
            {
                class: "text-notation login-ui-hide",
                text: "footer.disclosures.englishOnly"
            },
            {
                class: "copyright",
                text: "footer.disclosures.investingRisk"
            },
            {
                class: "copyright",
                text: "footer.disclosures.copyright"
            },
            {
                class: "text-notation",
                text: "footer.disclosures.notGuaranteed"
            }
        ],
        docs: [
            {
                href: "#/articles/securityCenter",
                text: "footer.disclosures.securityCenter",
                target: "_self"
            },
            {
                href: "#/articles/accessibility",
                text: "footer.disclosures.accessibility",
                target: "_self"
            },
            {
                href: "#/articles/systemRequirements",
                text: "footer.disclosures.systemRequirements",
                target: "_self"
            },
            {
                href: "#/articles/privacy",
                text: "footer.disclosures.privacy",
                target: "_self"
            },
            {
                href: "#/articles/terms",
                text: "footer.disclosures.terms",
                target: "_self"
            },
            {
                href: "#/articles/businessContinuity",
                text: "footer.disclosures.continuity",
                target: "_self"
            },
            {
                href: "#/articles/finra",
                text: "footer.disclosures.finra",
                target: "_self"
            },
            {
                href: "#/articles/policies",
                text: "footer.disclosures.policies",
                target: "_self"
            },
            {
                href: "#",
                text: "footer.disclosures.cookies",
                target: "_self",
                isOneTrust: true,
                onClickHandler: "OneTrust.ToggleInfoDisplay();"
            }
        ]
    },
    allocations: {
        doItForMePlacement: "standalone"
    },
    form: {
        beneficiaryDesignation: "#",
        contribution: "#",
        enrollment: "#",
        incomingTransfer: "#",
        informationChangeRequest: "#"
    },
    global: {
        accuCode: "Default",
        bucket: {
            aboutYourPlan:
                "You may benefit from <a href='#/articles/managedAccounts'>professional account management</a>, which offers  personalized recommendations on: <ul class='list-group flush red-arrow'><li>How much to save</li><li>What investments to choose</li><li>Tax-advantaged distribution options</li></ul>",
            investmentAssistance:
                "Log in to the website and look for the <em>Guidance</em> tab at the top of the page, or call and ask to speak with an investment adviser representative.",
            investmentInformation:
                "Go to the <em>Investments</em> section within the <em>My Accounts</em> section of the website to choose what's right for you. Or, if you seek investment assistance, look for the <em>Investing</em> help tab at the top of your website after logging in.",
            keyActions:
                "<ul class='list-group flush red-arrow'><li>Increase your contributions</li><li>Change your investment options</li><li>Sign up for investment assistance</li><li>Add or change your beneficiaries</li><li>Update your personal information</li></ul>"
        },
        link: {
            aboutYourPlanBreadcrumb: "#/articles/aboutYourPlan",
            assetAllocationFundsPage: "#/articles/assetAllocationFunds",
            exploreNowButton: "#/login",
            investmentAssistanceBreadcrumb: "#/articles/managedAccounts",
            investmentInformationBreadcrumb: "#/articles/investmentInformation",
            investmentInformationPage: "#/articles/investmentInformation",
            planHighlightsPage: "#/articles/planHighlights",
            planHighlightsPDF: "",
            registerButton: "#/register",
            terms: {
                termsOfUsePage: "#/articles/termsOfUse",
                termsAndConditionsPage: "#/articles/termsAndConditions",
                termsTextMessagingPage: "#/articles/termsTextMessaging"
            }
        },
        text: {
            enrollNowInvestmentAssistance:
                "It's quick and easy to enroll, but before you start, we encourage you to review the investment assistance options that are available to you by clicking on <em>Investment assistance</em> from the <em>Investing</em> dropdown menu at the top of the page. Choosing the right investments is an important part of your strategy, and there are several options to help you make your investment choices.",
            feesWordChoiceLowercase: "fees",
            feesWordChoiceUppercase: "Fees",
            GWSecureFoundationPlan: "Great-West SecureFoundation®",
            keepYourPlanPhoneNumber: "877-534-4569",
            participantWordChoiceLowercase: "participant",
            participantWordChoiceUppercase: "Participant",
            goalBasedPhoneNumber: "877-534-4569",
            planConsolidationPhoneNumber: "888-737-4480",
            investmentAlertHeadline: "",
            investmentAlertSubtext: "",
            securityGuaranteePhoneNumber: "844-773-6797",
            planWordChoiceLowercase: "plan",
            planWordChoiceUppercase: "Plan",
            registerButton: "Register",
            rightAngleQuote: " &raquo;",
            rightArrow: " &rarr;",
            terms: {
                cardDigitalExperience: "Terms and conditions for digital experience",
                cardEmpowerPersonalizedExperience:
                    "Terms of use for Empower Personalized Experience",
                cardTextMessaging: "SMS terms of service"
            }
        },
        image: {
            newWebsiteImageVersion: "1"
        }
    },
    buildYourOwnPortfolio: [
        {
            text: "Investment fundamentals",
            href: "#/articles/investmentFundamentals",
            target: "_self"
        },
        {
            text: "Asset classes",
            href: "#/articles/assetClasses",
            target: "_self"
        },
        {
            text: "Asset allocation funds",
            href: "#/articles/assetAllocationFunds",
            target: "_self"
        },
        {
            text: "Active vs. passive investment management",
            href: "#/articles/activePassiveInvestmentManagement",
            target: "_self"
        },
        {
            text: "Guaranteed retirement income",
            href: "#/articles/guaranteedRetirementIncome",
            target: "_self"
        }
    ],
    GWSecureFoundationPlan: "Great-West SecureFoundation®",
    GRIDisclosures: [
        {
            headerText: "For SF1 (both GW Funds and GW Trusts as covered fund) use:",
            disclosureText:
                "Great-West SecureFoundation® (SecureFoundation) is a guaranteed income benefit offered through a contingent deferred annuity issued by Great-West Life & Annuity Insurance Company, Corporate Headquarters: Greenwood Village, CO to your plan sponsor and is GWL&A’s promise to provide a payment of guaranteed income subject to the terms and conditions of the annuity and the claims-paying ability of GWL&A. SecureFoundation is only available through the purchase of a Great-West SecureFoundation covered fund. The guarantee income benefit is subject to an additional fee paid for through the sale of shares/units of the applicable Great-West SecureFoundation® covered fund, which reduces the covered fund value and is deducted quarterly.<sup>3</sup>"
        },
        {
            headerText: "For SF2 (both GW Funds and GW Trusts as covered fund) use:",
            disclosureText:
                "Great-West SecureFoundation® II (SecureFoundation II) is a guaranteed income benefit offered through a variable annuity issued by Great-West Life & Annuity Insurance Company, Corporate Headquarters: Greenwood Village, CO, or in New York, by Great-West Life & Annuity Insurance Company of New York , Home Office: New York, NY and is a promise to provide a payment of guaranteed income subject to the terms and conditions of the annuity and the claims-paying ability of GWL&A or GWL&A of NY, as applicable. SecureFoundation II is only available through the purchase of a Great-West SecureFoundation covered fund. The guarantee income benefit is subject to an additional fee paid for through the sale of shares/units of the applicable Great-West SecureFoundation® covered fund, which reduces the covered fund value and is deducted quarterly.<sup>3</sup>"
        },
        {
            headerText: "SF1 GW Funds and SF2 GW Funds:",
            disclosureText:
                "<sup>2</sup> The guaranteed income benefit does not guarantee the investment performance of the applicable Great-West SecureFoundation Fund. Neither Great-West Funds, Inc. nor Great-West Capital Management, LLC is responsible for any payments under Great-West SecureFoundation’s guaranteed income benefit."
        },
        {
            headerText: "SF1 GW Trusts and SF2 GW Trusts:",
            disclosureText:
                "<sup>2</sup> The guaranteed income benefit does not guarantee the investment performance of the Great-West SecureFoundation Balanced Trust. Neither Great-West Trust Company, LLC. nor Great-West Capital Management, LLC is responsible for any payments under Great-West SecureFoundation’s guaranteed income benefit."
        },
        {
            headerText: "For SF1 GW Funds and GW Trusts:",
            disclosureText:
                "<sup>3</sup> The cost for the fund is separate from the guaranteed benefit fee. GWL&A reserves the right to change the guaranteed benefit fee upon 30 days’ written notice. The amount of the annualized guaranteed benefit fee will not be higher than 1.5% or lower than 0.7% of your covered fund value. The guaranteed income benefit is contingent; thus, you may not reach the settlement phase (the point where the covered fund value is zero, but the insurer continues to pay withdrawal amounts) and may not recoup the guaranteed benefit fee paid. For more information, please see the SecureFoundation Summary Disclosure Statement or prospectus for 403(b) clients."
        },
        {
            headerText: "For SF2 GW Funds and GW Trusts:",
            disclosureText:
                "<sup>3</sup> The cost for the fund is separate from the guaranteed benefit fee. GWL&A, or GWL&A of NY as applicable, reserves the right to change the guaranteed benefit fee upon 30 days’ written notice. The amount of the annualized guaranteed benefit fee will not be higher than 1.5% or lower than 0.7% of your covered fund value. The guaranteed income benefit is contingent; thus, you may not reach the settlement phase (the point where the covered fund value is zero, but the insurer continues to pay withdrawal amounts) and may not recoup the guaranteed benefit fee paid. For more information, please see the SecureFoundation II Summary Disclosure Statement or prospectus for 403(b) clients."
        },
        {
            headerText: "For SF1 with GW Funds use:",
            disclosureText:
                "The guaranteed income benefit is not available in New York and may not be available in all states. Please check with your advisor or Empower Retirement representative for more information. GWL&A is not licensed to conduct insurance business in New York.<br>The principal underwriter of Great-West Funds, Inc. is GWFS Equities, Inc. Great-West Capital Management, LLC is the investment adviser. The SecureFoundation guaranteed income benefit does not guarantee the investment performance of the applicable Great-West SecureFoundation® fund. The actual purchase and acceptance of the SecureFoundation guarantee varies, depending on which Great-West SecureFoundation fund is chosen. SecureFoundation funds may invest in other funds advised by GWCM, funds that are sub-advised by affiliated and unaffiliated subadvisers retained by GWCM, funds that are advised by affiliated and unaffiliated investment advisers of GWCM and in a fixed-interest contract issued and guaranteed by GWL&A."
        },
        {
            headerText: "For SF1 with GW Trusts use:",
            disclosureText:
                "The guaranteed income benefit is not available in New York and may not be available in all states. Please check with your advisor or Empower Retirement representative for more information. GWL&A is not licensed to conduct insurance business in New York.<br>The guaranteed income benefit does not guarantee the investment performance of the applicable Great-West SecureFoundation trust. The guaranteed income benefit and related fee begin immediately upon purchase of the Great-West SecureFoundation(R) Balanced Trust. The Great-West collective investment trusts (CITs) are offered and maintained by trustee Great-West Trust Company, LLC and managed by its affiliate, Great-West Capital Management, LLC (GWCM). The CITs are not mutual funds and are exempt from SEC registration and applicable securities laws of any state or other jurisdiction. Designed for and exclusively sold to qualified retirement plans and their participants, CITs are not available to individual retail investors. Please see the applicable CIT disclosure documents for more information."
        },
        {
            headerText: "For SF2 with GW Funds use:",
            disclosureText:
                "Great-West Life & Annuity Insurance Company is not licensed to conduct insurance business in New York. Contract form numbers SFII 15 R (06-16) and SFII 15 U (06-16) and state variations are issued by GWL&A. In New York, NYSFII 15 R (06-16) and NYSFII 15 U (06-16) are issued by GWL&A of NY. May not be available in all states. Certain restrictions may apply. Please check with your advisor or Empower Retirement representative for more information.<br>Variable annuities are long-term investments and may not be suitable for all investors. Any withdrawals of earnings prior to the owner reaching age 59½ may be subject to income tax and a 10% penalty. An investment in a variable annuity is subject to fluctuating values of the underlying investment options, including the possible loss of principal.<br>The principal underwriter of Great-West Funds, Inc. is GWFS Equities, Inc. Great-West Capital Management, LLC (GWCM) is the investment adviser. The SecureFoundation guaranteed income benefit does not guarantee the investment performance of the applicable Great-West SecureFoundation® fund. The actual purchase and acceptance of the SecureFoundation guarantee varies, depending on which Great-West SecureFoundation fund is chosen."
        },
        {
            headerText: "For SF2 with GW Trusts use:",
            disclosureText:
                "Great-West Life & Annuity Insurance Company (GWL&A is not licensed to conduct insurance business in New York. The guaranteed income benefit and related fee begin immediately upon purchase of the Great-West SecureFoundation(R) Balanced Trust. Contract form numbers SFII 15 R (06-16) and SFII 15 U (06-16) and state variations are issued by GWL&A. In New York, NYSFII 15 R (06-16) and NYSFII 15 U (06-16) are issued by GWL&A of NY. May not be available in all states. Certain restrictions may apply. Please check with your advisor or Empower Retirement representative for more information.<br>Variable annuities are long-term investments and may not be suitable for all investors. Any withdrawals of earnings prior to the owner reaching age 59½ may be subject to income tax and a 10% penalty. An investment in variable annuity is subject to fluctuating values of the underlying investment options, including the possible loss of principal.<br>The guaranteed income benefit does not guarantee the investment performance of the applicable Great-West SecureFoundation trust. The guaranteed income benefit and related fee begin immediately upon purchase of the Great-West SecureFoundation® Balanced Trust. The CITs are not mutual funds and are exempt from SEC registration and applicable securities laws of any state or other jurisdiction. Designed for and exclusively sold to qualified retirement plans and their participants, CITs are not available to individual retail investors. Please see the applicable CIT disclosure documents for more information. Great-West Trust, LLC. and GWCM are wholly owned subsidiaries of GWL&A."
        }
    ],
    documentUpload: {
        fetchCategories: "false",
        categories: [
            {
                key: "transaction-documentation",
                label: "Transaction documentation"
            },
            {
                key: "personal-information-change",
                label: "Personal information change"
            },
            {
                key: "contributions-deferrals",
                label: "Contributions / Deferrals",
                txnCode: "WNPYIN"
            },
            {
                key: "incoming-rollovers",
                label: "Incoming rollovers"
            },
            {
                key: "other",
                label: "Other / I'm not sure"
            }
        ]
    },
    instAccus: ["AmFunds", "JPM", "MetLifeEE", "TraderJoes"],
    instPartnerAccu: ["MercerWise"],
    blockingMobileDevices: true,
    blockingMobileTemplate: "customizations/Default/articles/mobileDefaultLanding.html",
    showOpenAccountFooter: true,
    showLoginSetupRecoveryRadio: false,
    showOneTrustOptOut: true,
    oneTrustToken: {
        "retirementpartner.com": {
            test: "1ce15132-b058-4916-ba8e-0bdfb7fd3424-test",
            prod: "1ce15132-b058-4916-ba8e-0bdfb7fd3424"
        },
        "empower-retirement.com": {
            test: "fe302350-870f-4223-b433-a6cfc02b6096-test",
            prod: "fe302350-870f-4223-b433-a6cfc02b6096"
        }
    },
    addOptimizelyScript: true,
    smartRetirement: {
        topHeading: "Learning Center",
        ktmgs: "WNRTIN,WNJPC1,WNJPC2,WNJPC3",
        cards: [
            {
                heading: "How-to video - Enroll in your plan online",
                columnOne: {
                    blockOne:
                        "Participating in your retirement plan is one of the easiest and most effective ways to achieve your retirement goals. Learn how to enroll in your plan.",
                    blockTwo: {
                        heading: "",
                        body: ""
                    }
                },
                columnTwo: {
                    imageOneSrc:
                        "customizations/JPM/images/smartRetirement/enroll-in-your-plan-online-image.png"
                },
                ktmg: "WNRTIN",
                buttons: {
                    primary: {
                        action: "Watch now > (6 min)",
                        actionLink:
                            "https://www.loom.com/share/9583decd5af84d82b90f6df3e08b746e?sid=1a86b7c1-d2b1-42e0-956e-a037ba346d99"
                    },
                    secondary: ""
                }
            },
            {
                heading: "How-to video - Designate your beneficiary",
                columnOne: {
                    blockOne:
                        "Selecting a beneficiary is an important step in setting up your retirement plan account. Learn how to designate your beneficiary.",
                    blockTwo: {
                        heading: "",
                        body: ""
                    }
                },
                columnTwo: {
                    imageOneSrc:
                        "customizations/JPM/images/smartRetirement/designate-your-beneficiary-image.png"
                },
                ktmg: "WNRTIN",
                buttons: {
                    primary: {
                        action: "Watch now > (5 min)",
                        actionLink:
                            "https://www.loom.com/share/406e382aec6a40fdb3b77a07e9b2ea44?sid=2383f705-20a1-46ab-aa78-8852097a822f"
                    },
                    secondary: ""
                }
            },
            {
                heading: "How-to video - Change your contributions",
                columnOne: {
                    blockOne:
                        "Increasing your contribution by 1-2% on an annual basis is a great way to get you on a more secure retirement path. Learn how to change your contributions.",
                    blockTwo: {
                        heading: "",
                        body: ""
                    }
                },
                columnTwo: {
                    imageOneSrc:
                        "customizations/JPM/images/smartRetirement/change-your-contribution-image.png"
                },
                ktmg: "WNRTIN",
                buttons: {
                    primary: {
                        action: "Watch now > (4 min)",
                        actionLink:
                            "https://www.loom.com/share/6789eaacdc42485dbb80df2673ae6c85?sid=4882031e-46d9-45b4-90c6-e65727334414"
                    },
                    secondary: ""
                }
            },
            {
                heading: "How-to video - Automate your savings",
                columnOne: {
                    blockOne:
                        "Describes different ways of automating your retirement account like increasing contribution your rate and rebalancing your investments.",
                    blockTwo: {
                        heading: "",
                        body: ""
                    }
                },
                columnTwo: {
                    imageOneSrc:
                        "customizations/JPM/images/smartRetirement/automate-your-savings-image.png"
                },
                ktmg: "WNRTIN",
                buttons: {
                    primary: {
                        action: "Watch now > (4 min)",
                        actionLink:
                            "https://www.loom.com/share/9f60b87765c6448092b06d9fb639c936?sid=24c4fe59-2ba2-45a1-8db4-de339ffd54ff"
                    },
                    secondary: ""
                }
            },
            {
                heading: "How-to video - Protect your identity",
                columnOne: {
                    blockOne:
                        "Protect yourself against cyber security fraud using J.P. Morgan Retirement Link's website. Learn how to protect your identity.",
                    blockTwo: {
                        heading: "",
                        body: ""
                    }
                },
                columnTwo: {
                    imageOneSrc:
                        "customizations/JPM/images/smartRetirement/protect-your-identity-image.png"
                },
                ktmg: "WNRTIN",
                buttons: {
                    primary: {
                        action: "Watch now > (5 min)",
                        actionLink:
                            "https://www.loom.com/share/339cdd5e036942bdb8840fd7ff50ad2b?sid=c7730ae0-4a18-4d84-a0b6-18720324c673"
                    },
                    secondary: ""
                }
            },
            {
                heading: "Understanding your retirement account statements",
                columnOne: {
                    blockOne:
                        "Statements are important documents yet can be difficult to understand.  Let us provide a simplified overview of the key component of your retirement account statements and how to access them online when needed.",
                    blockTwo: {
                        heading: "",
                        body: ""
                    }
                },
                columnTwo: {
                    imageOneSrc:
                        "customizations/JPM/images/smartRetirement/explore-roth-401k-image-version-1.png"
                },
                ktmg: "WNRTIN",
                buttons: {
                    primary: {
                        action: "Watch now > (6 min)",
                        actionLink:
                            "https://www.loom.com/share/65f08ed2bd6e45728ea9d693c196d411?sid=4adde5dc-6ab0-44df-80b7-a143f1980fa2"
                    },
                    secondary: ""
                }
            },
            {
                heading: "JPMorgan SmartRetirement Funds",
                columnOne: {
                    blockOne:
                        "Want to learn more about how target date funds work? As a target date fund, JPMorgan SmartRetirement, can help prepare you for the approximate year you plan to retire. It provides a single place to invest the savings that you have in your employer's retirement plan.",
                    blockTwo: {
                        heading: "",
                        body: ""
                    }
                },
                columnTwo: {
                    imageOneSrc:
                        "customizations/JPM/images/smartRetirement/jpm-smartretirement-target-date-funds.png"
                },
                ktmg: "WNJPC1",
                buttons: {
                    primary: {
                        action: "Learn more",
                        actionLink:
                            "https://am.jpmorgan.com/us/en/asset-management/smartretirement/"
                    },
                    secondary: ""
                }
            },
            {
                heading: "JPMorgan SmartRetirement Blend Funds",
                columnOne: {
                    blockOne:
                        "Want to learn more about how target date funds work? As a target date fund, JPMorgan SmartRetiremet Blend, can help prepare you for the approximate year you plan to retire. It provides a single place to invest the savings that you have in your employer's retirement plan.",
                    blockTwo: {
                        heading: "",
                        body: ""
                    }
                },
                columnTwo: {
                    imageOneSrc:
                        "customizations/JPM/images/smartRetirement/jpm-smartretirement-target-date-funds.png"
                },
                ktmg: "WNJPC2",
                buttons: {
                    primary: {
                        action: "Learn more",
                        actionLink:
                            "https://am.jpmorgan.com/us/en/asset-management/smartretirement-blend/"
                    },
                    secondary: ""
                }
            },
            {
                heading: "J.P. Morgan Wealth Management IRA Rollover",
                columnOne: {
                    blockOne:
                        "Are you ready to roll over an old 401(k) into an IRA? Learn about J.P. Morgan Wealth Management and rollover options. Contact our specialty Retirement Desk at 1-833-ROLLOVR (1-833-765-5687) for assistance.",
                    blockTwo: {
                        heading: "",
                        body: "Disclosure: This bulletin is a general communication being provided for informational purposes only. It is educational in nature and not designed to be a recommendation or investment advice for any specific investment product or strategy. None of J.P. Morgan Asset Management, its affiliates or representatives is suggesting that the recipient or any other person take a specific course of action or any action at all.  Investors generally have multiple available options for their old 401(k) plan balances. Prior to making any investment or financial decisions, an investor should seek individualized advice from personal financial, legal, tax and other professionals that take into account all of the particular facts and circumstances of an investor's own situation."
                    }
                },
                columnTwo: {
                    imageOneSrc:
                        "customizations/JPM/images/smartRetirement/jpm-wealth-management-rollover-image.png"
                },
                ktmg: "WNJPC3",
                buttons: {
                    primary: {
                        action: "Rollover options",
                        actionLink:
                            "https://www.jpmorgan.com/content/dam/jpm/structured-products-documents/2020/nov/rollover.pdf"
                    },
                    secondary: {
                        action: "J.P. Morgan Wealth Management",
                        actionLink:
                            "https://image.gim.jpmorgan.com/lib/fe92137277640d7f72/m/1/8e23ee27-6a1c-44f0-a093-c06005900384.pdf"
                    }
                }
            }
        ]
    },
    isLoginLite: true,
    isLoginPart: false,
    enterpriseChat: {
        awsDefaultRegion: "us-east-1",
        buttonColor: "#004185",
        contactFlowId: "ed766666-b17e-4176-8f4d-e67697f94181",
        instanceId: "09924a5d-0c60-43d4-bf26-0c19a6b1a635",
        initiationIcon: "icon",
        initiationButton: "button",
        showPreLoginEnterpriseChat: false
    }
};
