import React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { stripNonDigits } from "../../utils/formUtils/stripNonDigits";
import ControlledFormField from "../shared/controlledFormField/ControlledFormField";

import { useTranslations } from "../../queries";
import authenticateSms from "../../services/redwoodMfa/authenticateSms";
import challengeSms from "../../services/redwoodMfa/challengeSms";
import { getAccuCode } from "../../services/accuCodeService";
import { REDWOOD_MFA_PHONE } from "../../routes";
import challengePhone from "../../services/redwoodMfa/challengePhone";

interface RedwoodMfaTranslation {
    app: {
        common: {
            labels: {
                cancel: string;
            };
        };
    };
    button: {
        cancel: string;
        continue: string;
    };
    redwoodMfa: {
        redWoodMfaTitle: string;
        smsAuthenticate: {
            authroizeSms: string;
            codeRequired: string;
            resendTxt: string;
            smsDescription: string;
            smsTitle: string;
            smsValid: string;
        };
    };
}
interface RedwoodSmsAuthenticateFields {
    smsCode: string;
}
const DEFAULT_VALUES = {
    smsCode: ""
};

const LandingSmsRedwoodMfa = () => {
    const {
        control,
        handleSubmit,
        setError,
        formState: { isSubmitting }
    } = useForm<RedwoodSmsAuthenticateFields>({
        defaultValues: DEFAULT_VALUES,
        mode: "onBlur",
        criteriaMode: "all"
    });
    const csrfToken = (window as Window).csrf;
    const navigate = useNavigate();
    const { redwoodMfa, button, app } = useTranslations<RedwoodMfaTranslation>();
    const accuCode = getAccuCode();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isReact = searchParams.get("isReact");

    const onSubmit = async (formData: RedwoodSmsAuthenticateFields) => {
        const { smsCode } = formData;
        try {
            const authData = await authenticateSms({ csrfToken, smsCode });
            if (authData.authLevel === "SESSION_AUTHENTICATED" && authData.status === "ACTIVE") {
                sessionStorage.setItem("userGuidToken", authData.userGuid);
                sessionStorage.setItem("authenticated", authData.username);
                (window as Window).location = "" + "/dashboard/#/home";
            }
        } catch (err) {
            console.error(err);
            if (err instanceof Error) {
                setError("root", { type: "manual", message: err.message });
            } else {
                setError("root", { type: "manual", message: "An unknown error occurred" });
            }
        }
    };
    const handleAuthorizePhone = async () => {
        const authData = await challengePhone({ csrfToken });
        if (authData.authLevel === "USER_IDENTIFIED" && authData.status === "ACTIVE") {
            navigate(REDWOOD_MFA_PHONE);
        }
    };
    const handleResend = (event) => {
        event.preventDefault();
        try {
            challengeSms({ csrfToken });
        } catch (err) {
            console.error(err);
            setError("root", {
                type: "manual",
                message: err instanceof Error ? err.message : "An unknown error occurred"
            });
        }
    };
    const handleCancel = () => {
        window.location.href = `/participant/#/login?accu=${accuCode}`;
    };
    const SMS_CODE_RULES = {
        validate: {
            isRequired: (value: string) =>
                !value ? redwoodMfa.smsAuthenticate.codeRequired : undefined
        }
    };
    const continueButton = button.continue.toUpperCase();
    return (
        <form data-testid="landing-sms-redwood-mfa" onSubmit={handleSubmit(onSubmit)}>
            <div className="container row redwood-mra-container">
                <div className="row">
                    <div
                        className={`registration col-xs-7 ${isReact ? "col-xs-offset-6" : "col-xs-offset-3"}`}
                    >
                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane active">
                                <div className="align-center">
                                    <h1 className="">{redwoodMfa.redWoodMfaTitle}</h1>
                                    <p>{redwoodMfa.smsAuthenticate.smsDescription}</p>
                                    {isSubmitting && (
                                        <div className="loaderBackground">
                                            <div className="loader"></div>
                                        </div>
                                    )}
                                    <ControlledFormField
                                        className="col-4 col-xs-offset-4"
                                        control={control}
                                        id="sms-code"
                                        label={redwoodMfa.smsAuthenticate.smsTitle}
                                        maxLength={4}
                                        name="smsCode"
                                        onChange={(e) => stripNonDigits(e.target.value)}
                                        rules={SMS_CODE_RULES}
                                        extraAriaDescribedBy="sms-valid"
                                    />
                                    <p id="sms-valid">{redwoodMfa.smsAuthenticate.smsValid}</p>

                                    <div className="button-group">
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-lg margin-top-default outline-btn"
                                        >
                                            {continueButton}
                                        </button>
                                    </div>
                                    <div className="cancel-btn-align">
                                        <button className="cancel-button" onClick={handleCancel}>
                                            {app.common.labels.cancel}
                                        </button>
                                    </div>
                                    <div className="button-group">
                                        <button
                                            className="resendSmsBtn"
                                            type="button"
                                            onClick={handleResend}
                                        >
                                            {redwoodMfa.smsAuthenticate.resendTxt}
                                        </button>
                                        <button
                                            className="resendSmsBtn"
                                            onClick={handleAuthorizePhone}
                                        >
                                            {redwoodMfa.smsAuthenticate.authroizeSms}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default LandingSmsRedwoodMfa;
