import React from "react";
import type { UseFormRegister, UseFormTrigger } from "react-hook-form";
import { RegisterFields } from "./types";

interface CountrySelectProps {
    contactDetails: {
        countryDetails: [
            {
                countryCode: string;
                countryName: string;
                countryPhNo: string;
            }
        ];
        domesticCntryCode: string;
        intCntryCode: string;
    };
    name: keyof RegisterFields;
    register: UseFormRegister<RegisterFields>;
    trigger: UseFormTrigger<RegisterFields>;
}

const CountrySelect = ({ register, trigger, contactDetails, name }: CountrySelectProps) => {
    const defaultVal =
        name[name.length - 1] === "D"
            ? contactDetails.domesticCntryCode
            : contactDetails.intCntryCode || contactDetails.countryDetails[0]?.countryCode || "";

    return (
        <select
            {...register(name, {
                required: true,
                onBlur: (e) => trigger(e.target.name)
            })}
            className="form-control"
            id="contactCountryName"
            defaultValue={defaultVal}
        >
            {contactDetails.countryDetails.map((option) => {
                return (
                    <option
                        key={option.countryCode}
                        value={`${option.countryCode}${option.countryPhNo}`}
                        aria-label={option.countryName}
                    >
                        {option.countryName}
                    </option>
                );
            })}
        </select>
    );
};

export default CountrySelect;
