import type { AxiosResponse } from "axios";
import ReferenceData from "../../../constants/ReferenceData";
import { executePost } from "../../../utils/appUtils";
import { RedirectData } from "../../redirect/types";

interface RegisterWithoutPinPayload {
    accu: string | undefined;
    dateOfBirth: string;
    flowName: string;
    isIDProofing: string;
    lastName?: string;
    metrixPlanId?: string;
    ssn?: string;
    uniqueId?: string;
    zipcode?: string;
}

const registerWithoutPin = (
    payload: RegisterWithoutPinPayload
): Promise<AxiosResponse<RedirectData>> => {
    const registerSuccess = (results) => {
        if (results && results.data) {
            return results;
        }
    };

    return executePost(ReferenceData.REGISTER_WITHOUT_PIN, "registerWithoutPinFault", payload)
        .then(registerSuccess)
        .catch((err) => {
            console.log("register without pin Error: ", err);
            throw err;
        });
};

export default registerWithoutPin;
