import { PhoneNumberUtil } from "google-libphonenumber";

const InternationalPhoneNumUtil = function InternationalPhoneNumUtil() {
    return {
        formatToE164: formatToE164
    };

    function formatToE164(phoneNumber, countryCode) {
        const phoneUtil = PhoneNumberUtil.getInstance();
        try {
            const phNumber = phoneUtil.parse(phoneNumber, countryCode);

            // what  is this doing>?>?
            // eslint-disable-next-line no-undef
            return phoneUtil.format(phNumber, PNF.INTERNATIONAL);
        } catch (err) {
            console.log(
                "error formatting the phone number with " +
                    phoneNumber +
                    ":country code: " +
                    countryCode +
                    ". Error: " +
                    err
            );
            return phoneNumber;
        }
    }
};

InternationalPhoneNumUtil.$inject = ["$resource", "$log"];
export default InternationalPhoneNumUtil;
