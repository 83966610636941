import React, { useEffect } from "react";
import RegisterCredentials from "./RegisterCredentials";
import { useForm } from "react-hook-form";
import SubmitButton from "./SubmitButton";
import { useTranslations } from "../../queries";
import { useMutation } from "@tanstack/react-query";
import { registerUser } from "../../services/accountSetup/accountSetupService";
import type { RegisterFields, SetupTranslations, RegisterCredTranslations } from "./types";
import useRedirect from "../../hooks/useRedirect";
import { RedirectDataWithErrorFields } from "../../services/redirect/types";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import RegisterEvents from "../../../accountSetup/events/register";

const RegisterInfo = () => {
    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors },
        trigger
    } = useForm<RegisterFields>({
        criteriaMode: "all",
        mode: "all"
    });
    const redirect = useRedirect();
    const { accountSetup }: { accountSetup: SetupTranslations } = useTranslations();
    const { BR_203 } = useTranslations<RegisterCredTranslations>();
    const [registerUserError, setRegisterUserError] = React.useState("");
    const mutation = useMutation({
        mutationFn: registerUser,
        onSuccess: (response: RedirectDataWithErrorFields) => {
            setRegisterUserError("");
            if (response.status && response.status === "FAIL") {
                if (response?.error?.code === "BR_203") {
                    setRegisterUserError(BR_203);
                } else {
                    setRegisterUserError(response?.error?.message);
                }
            } else {
                redirect(response.data);
            }
        }
    });

    const handleValidationError = () => {
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: RegisterEvents.ATTEMPT_SUBMIT_CREATE_USERNAME_PASSWORD
        });
    };

    const handleRegisterUser = (data) => {
        const flowName = "registrationFlow";
        const params = {
            username: data.username,
            password: data.password,
            flowName
        };
        mutation.mutate(params);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: RegisterEvents.SUCCESSFUL_SUBMIT_CREATE_USERNAME_PASSWORD
        });
    };

    const showSpinner = mutation.status === "pending";

    useEffect(() => {
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.VIEW_PAGE,
            selection: RegisterEvents.PGE_VIEW_CREATE_USERNAME_PASSWORD
        });
    }, []);

    return (
        <div className="registration-container register-info">
            <div className="form registration">
                <header className="contained-form-header">
                    <h1>{accountSetup.usernamePasswordTitle}</h1>
                </header>

                <div className="inner-container with-padding with-shadow">
                    {registerUserError > "" && (
                        <div className="form-group has-error" aria-live="assertive">
                            <p className="help-block">{BR_203}</p>
                        </div>
                    )}
                    <form
                        id="registration-form"
                        name="accountSetupForm"
                        onSubmit={handleSubmit(handleRegisterUser, handleValidationError)}
                        noValidate
                        autoComplete="off"
                    >
                        <RegisterCredentials
                            register={register}
                            getValues={getValues}
                            trigger={trigger}
                            errors={errors}
                        />

                        <div className="form-group clearfix">
                            <SubmitButton showSpinner={showSpinner} isRegistrationFlow={true} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default RegisterInfo;
