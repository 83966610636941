import { useQuery } from "@tanstack/react-query";
import retrieveUserNameForOneIdService from "../../services/profile/retrieveUserNameForOneIdService";

const useRetrieveUserNameForOneId = () => {
    return useQuery({
        queryKey: ["retrieveUserNameForOneId"],
        queryFn: retrieveUserNameForOneIdService
    });
};

export default useRetrieveUserNameForOneId;
